<template>
  <div>
    <p-radio
      :name="name"
      :checked="checked"
      :class="classes"
      class="hs-radio p-default p-round"
      @change="handleChange"
    >
      <slot></slot>
    </p-radio>
  </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/radio';

export default {
  components: {
    'p-radio': PrettyCheck,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    name: [String, Number],
    value: [String, Number],
    checked: Boolean,
    type: String,
    disabled: Boolean,
  },
  computed: {
    classes() {
      const res = [];
      if (this.type) res.push(`hs-radio--type-${this.type}`);
      if (this.disabled) res.push('hs-radio--disabled');
      return res;
    },
  },
  methods: {
    handleChange(checked) {
      this.$emit('change', {checked, value: this.value, name: this.name});
    },
  },
};
</script>

<style lang="scss">
@import '~pretty-checkbox/src/pretty-checkbox.scss';
$radioBorderColor: #d1d7db;
$radioDisabledBGColor: #eff0f1;
$radioDimensions: 16px;

.hs-radio {
  &.pretty .state label::before,
  &.pretty .state label::after {
    top: 0;
    width: $radioDimensions;
    height: $radioDimensions;
  }
  &.pretty .state label::after {
    transform: scale(0.5);
  }
  &--disabled {
    pointer-events: none;
    &.pretty.p-curve .state label::before {
      z-index: 1;
    }
    &.pretty.p-curve .state label::before,
    &.pretty.p-curve .state label::after {
      background-color: $radioDisabledBGColor !important;
    }
  }
  &.pretty.p-default.p-round input:checked ~ .state label::after {
    z-index: 1;
    background-color: $white !important;
  }
  &.pretty input:checked ~ .state label::before {
    background-color: $blue !important;
    border: 0;
  }
  &--type-red.pretty input:checked ~ .state label::before {
    background-color: $primaryRed !important;
  }
}
</style>
