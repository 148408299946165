export default {
  CLAIM_CARRYIN: 'Carry-In Service',
  CLAIM_DROPOFF: 'Depot Service',
  CLAIM_SELF_REPAIR: 'Reimbursement',
  CLAIM_ONSITE: 'Home Service',

  REPLACEMENT: 'Replacement', // workaround for eaerlier Replacement claims
  CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE: 'Replacement',

  CLAIM_BUYOUT_WITHOUT_DEFECTIVE: 'Buyout',
  'Buyout - Reject': 'Buyout',
  'BUYOUT - REJECT': 'Buyout',
  'Buyout - Accept': 'Buyout',
  'BUYOUT - ACCEPT': 'Buyout',
  'Manual Buyout': 'Buyout',
  'MANUAL BUYOUT': 'Buyout',
};
