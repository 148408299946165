import {getInstance} from '@/libs/auth/index';

let unwatchAuthInstanceLoading = null;
let pendingTokenPromisesHandlers = [];

export default function getAuthorizationToken() {
  return new Promise((resolve, reject) => {
    pendingTokenPromisesHandlers.push({resolve, reject});

    if (!unwatchAuthInstanceLoading) {
      const instance = getInstance();
      unwatchAuthInstanceLoading = instance.$watch(
        'loading',
        async (loading) => {
          if (!loading) {
            if (!unwatchAuthInstanceLoading) {
              // unwatchAuthInstanceLoading won't be available
              // if immediate if condition resolves to true immediately
              instance.$nextTick(() => unwatchAuthInstanceLoading());
            } else {
              unwatchAuthInstanceLoading();
            }
            try {
              const token = await instance.getTokenSilently();
              for (const {resolve} of pendingTokenPromisesHandlers) {
                resolve(token);
              }
            } catch (e) {
              for (const {reject} of pendingTokenPromisesHandlers) {
                reject(e);
              }
            } finally {
              unwatchAuthInstanceLoading = null;
              pendingTokenPromisesHandlers = [];
            }
          }
        },
        {
          immediate: true,
        }
      );
    }
  });
}
