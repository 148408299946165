<template>
  <div class="hs-search">
    <label class="hs-search__label">
      <input
        :value="value"
        type="search"
        :placeholder="placeholder"
        class="hs-search__field"
        @input="catchInput"
      />
      <span class="hs-search__icon">Search icon</span>
    </label>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'value'],
  methods: {
    catchInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.hs-search {
  align-self: flex-start;
  &__label {
    position: relative;
    display: block;
  }
  &__field {
    display: block;
    width: 100%;
    height: 44px;
    padding: 0 15px;
    color: $mediumGray;
    font-size: 14px;
    line-height: 19px;
    background: #f9f9f9;
    border: 1px solid #d1d1d1;
    border-radius: 4px;

    @include mf($gridMDBreakpoint) {
      font-size: 16px;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    text-indent: -9999px;
    background: url('./search.svg') no-repeat 0 0/18px 18px;
    border: none;
    transform: translate(0, -50%);
  }
  &__field:focus ~ &__icon {
    display: none;
  }
}
</style>
