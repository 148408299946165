import {getRequestBody} from '@/utils/request';
import claimTypesNames from '@/constants/claim_types_names';

export function getAddressField(
  serviceRequestAddressFieldName,
  customerDetailsAddressFieldName,
  defaultValue = ''
) {
  return ({useCustomAddress, address}, getters, {user}) => {
    let result = defaultValue;
    const rootAddress = user.customerDetails;

    const AddressDetail = {
      HouseNo: rootAddress?.houseNo,
      AddressLine2: rootAddress?.addressLine2,
      State: rootAddress?.state,
      ZipCode: rootAddress?.zipCode,
      Country: rootAddress?.country,
      City: rootAddress?.city,
      StreetName: rootAddress?.addressLine1,
    };

    if (useCustomAddress) {
      result = address?.[serviceRequestAddressFieldName];
    } else if (AddressDetail) {
      result = AddressDetail?.[customerDetailsAddressFieldName];
    }

    // purely for consistency
    if (serviceRequestAddressFieldName === 'country') {
      if (!result || result === 'USA') {
        result = 'US';
      }
    }

    return result;
  };
}

export const getters = {
  deviceForTitle(state, getters, rootState) {
    return rootState?.deviceInformation?.equipmentName || rootState?.deviceInformation?.modelName;
  },
  serviceHistory(state, getters, rootState) {
    if (rootState.jobInfo?.claimsList?.length && rootState.myDevices?.devices?.length) {
      const devicesMap = {};
      rootState.myDevices?.devices.forEach((i) => {
        devicesMap[i.inventoryEquipmentId] = i;
      });
      return rootState.jobInfo?.claimsList
        .map((i) => {
          const device = devicesMap[i?.claimDetails?.inventoryEquipmentID] || {};
          let date = i?.claimDetails?.createDate;
          const dateInst = date ? new Date(date) : '';
          if (dateInst) {
            date = `${dateInst.getMonth() + 1}/${dateInst.getDate()}/${dateInst.getFullYear()}`;
          }
          return {
            id: i.serviceJobID,
            title:
              device.equipmentName || device.modelName || i?.claimDetails?.inventoryEquipmentID,
            sn: i.serviceJobID,
            addDate: date,
            number: i.contractNumber,
            type:
              claimTypesNames[i?.serviceJobType] ||
              claimTypesNames[i?.serviceJobType?.toUpperCase()],
            claimType: i?.serviceJobType,
            status: i?.serviceJobStatus,
            serviceContractProductCode: i?.serviceContractProductCode,
          };
        })
        .filter((i) => {
          return (
            i.status !== 'INITIATED' &&
            rootState?.user?.cePlanCodes.includes(i.serviceContractProductCode)
          );
        });
    }
    return [];
  },
  availableOptions(state) {
    let res = [];
    if (state.adjudicationResponse) {
      res = [...state.adjudicationResponse?.allowedModes?.allowedMode];
      if (!res.length) {
        res.push('Reimbursement');
      }

      if (res.indexOf('Repair') !== -1) {
        res.splice(res.indexOf('Repair'), 1);
      }
    }
    if (state.availabilityResponses && state.availabilityResponses.Repair) {
      const availabilities = state.availabilityResponses?.Repair?.serviceAvailabilities;
      if (availabilities && availabilities.length) {
        for (const availability of availabilities) {
          if (
            availability?.isAvailable &&
            (availability?.isAvailable === 'true' || availability?.isAvailable === true)
          ) {
            res.unshift(availability?.serviceTypeCode);
          }
        }
      }
    }

    return res;
  },

  selectedIssue: ({issues, issue}) => {
    return issues.find((i) => i.issueCode === issue) || {};
  },

  serviceCallFee: ({productLineName}, getters, rootState, rootGetters) => {
    const productLinesClaims = rootGetters['user/productLinesClaims'];
    return productLinesClaims[productLineName]?.product_Service_Call_Fee;
  },

  buyoutCost: (state) => state.adjudicationResponse?.buyoutCost || '',

  state: getAddressField('state', 'State'),
  city: getAddressField('city', 'City'),
  zipcode: getAddressField('zip', 'ZipCode'),
  country: getAddressField('country', 'Country', 'US'),
  houseNo: getAddressField('houseNo', 'HouseNo'),
  flatNo: getAddressField('flatNo', 'FlatNo'),
  flatNoWithApt: (_, {flatNo}) => {
    let result = flatNo;
    if (flatNo && !flatNo.toLowerCase().includes('apt')) {
      result = `Apt ${flatNo}`;
    }
    return result;
  },
  streetName: getAddressField('streetName', 'StreetName'),

  selectedAddress: (_, {state, city, zipcode, country, flatNo, houseNo, streetName}) => {
    return {state, city, zipcode, flatNo, country, houseNo, streetName};
  },

  selectedAddressLandmark: (_, {selectedAddress}) => {
    const {city, state, zipcode} = selectedAddress;
    return `${city}, ${state} ${zipcode}`;
  },

  selectedAddressText: (_, {houseNo, streetName}) => `${streetName}`,

  selectedAddressWithApartmentText: (_, {selectedAddressText, flatNo}) =>
    flatNo ? `${selectedAddressText}, ${flatNo}` : selectedAddressText,

  fullSelectedAddressText: (_, {selectedAddressLandmark, selectedAddressText}) =>
    `${selectedAddressText} ${selectedAddressLandmark}`,

  addressPayload: (
    _,
    {selectedAddress, flatNoWithApt, selectedAddressText, selectedAddressLandmark}
  ) => {
    const {state, city, zipcode, houseNo, streetName} = selectedAddress;
    return {
      state,
      city,
      houseNo,
      flatNo: flatNoWithApt,
      streetName,
      zipcode,
      address: selectedAddressText,
      landmark: selectedAddressLandmark,
    };
  },

  availableServices: ({availabilityResponses}) => {
    let availableServices = {};
    console.log('availabilityResponses');
    //console.log(availabilityResponses);

    if (availabilityResponses) {
      const list = [].concat(
        ...Object.values(availabilityResponses).map(
          (servicesGroup) => servicesGroup.serviceAvailabilities
        )
      );
      console.log('list');
      //console.log(list);
      for (const service of list) {
        availableServices[service.serviceTypeCode] = service;
      }
    }

    return availableServices;
  },

  getServiceLocationCode:
    (_, {availableServices}) =>
    (serviceTypeCode) => {
      const service = availableServices[serviceTypeCode];
      return service?.serviceLocation?.[0]?.serviceLocationCode;
    },

  getServiceLocationName:
    (_, {availableServices}) =>
    (serviceTypeCode) => {
      const service = availableServices[serviceTypeCode];
      return service?.serviceLocation?.[0]?.serviceLocationName;
    },

  getStartClaimMasterPayload: (state, getters, rootState, rootGetters) => (payload) => {
    const {serviceTypeCode, requestParams = {}, consumerServiceRequestParams = {}} = payload;

    const {customerRef, contractRef} = requestParams;

    const {selectedIssue, addressPayload, buyoutCost} = getters;

    const {
      brandCode,
      productLine,
      productLineName,
      issue,
      triageReferenceId,
      geoLocation,
      details,
    } = state;

    const serviceDescription = selectedIssue?.issue;
    const {issueCategoryCode} = selectedIssue;
    const user = rootGetters['user/fullName'];
    const planCode = rootGetters['user/planCode'];
    const partnerCode = rootGetters['user/partnerCode'];
    const {time, description} = details;

    const requestPayload = getRequestBody({
      user,
      requestName: 'createClaimsMasterRequest',
      requestParams: {
        customerName: user,
        context: 'create',
        sourceSystem: 'SERVIFY',
        optionPosition: '1',
        serviceJobStatus: 'INITIATED',
        countryCode: 'US',
        phoneCode: '1',

        productLine,
        productSubCategoryCode: productLineName,
        productCode: planCode,
        partnerCode,
        PartnerCode: partnerCode,

        brandCode,
        triageReferenceId,
        triageReferenceID: triageReferenceId, // TODO: Make sure spelling is correct.

        serviceDescription,
        customerRef,
        contractRef,

        issueCategoryCode,
        complaintCode: issue,
        complaintCodeName: serviceDescription,

        ...requestParams,

        consumerServiceRequest: {
          serviceDelivery: serviceTypeCode,
          addressType: 'Home', // TODO: Get this from API or SC.
          serviceTypeCode: 'POLICY', // <- Decided to hardcode this value. Ask Jagan for details
          buyoutAmount: buyoutCost,
          lossDateTime: time,
          decriptionOfLoss: description,
          descriptionOfLoss: description,
          ...addressPayload,
          ...geoLocation,
          ...consumerServiceRequestParams,
        },
      },
    });

    return requestPayload;
  },

  getStartClaimPayload: (state, getters, rootState, rootGetters) => (payload) => {
    const {
      customerRef,
      serviceTypeCode,
      productSubCategoryCode,
      issueCode,
      issueRemarks,
      issueCategoryCode,
      lossDateTime,
      descriptionOfLoss,
      externalReferenceID,
      requestParams = {},
      consumerServiceRequestParams = {},
    } = payload;

    const {addressPayload, getServiceLocationCode, getServiceLocationName} = getters;

    const name = rootGetters['user/fullName'];
    const mobileNumber = rootGetters['user/mobileNumber'];
    const emailID = rootGetters['user/email'];
    const serviceLocationCode = getServiceLocationCode(serviceTypeCode);
    const serviceLocationName = getServiceLocationName(serviceTypeCode);
    const {geoLocation} = state;

    const productCode = rootGetters['deviceInformation/model'];
    const brandCode = rootGetters['deviceInformation/make'];
    const imeiOrSerial = rootGetters['deviceInformation/serialNumber'];

    const requestPayload = getRequestBody({
      requestName: 'createClaimRequest',
      requestParams: {
        customerRef,
        //referenceID: '',
        salesChannel: '',
        countryCode: 'US',
        phoneCode: '1',
        planCode: 'HMSRV12MEWADLD', // TODO: Move to env var.
        coveredValue: '1000', // TODO: Get this from API or SC.
        currencyCode: 'USD',

        name,
        user: name,
        mobileNumber,
        emailID,
        productCode,
        brandCode,
        productSubCategoryCode,
        imeiOrSerial,
        externalReferenceID,
        issues: [
          {
            issueCode,
            issueRemarks,
            issueCategoryCode,
          },
        ],

        ...requestParams,

        consumerServiceRequest: {
          addressType: 'Home', // TODO: Get this from API or SC.

          serviceTypeCode,
          serviceLocationCode,
          serviceLocationName,

          ...geoLocation,
          ...addressPayload,
          ...consumerServiceRequestParams,
        },

        consumerServiceRequestDetails: {
          lossDateTime,
          descriptionOfLoss,
        },
      },
    });
    delete requestPayload.request['createClaimRequest'].context;

    return requestPayload;
  },

  getRaiseClaimPayload:
    ({productLine}, {getStartClaimPayload, zipcode, state, selectedIssue}) =>
    (payload) => {
      const requestPayload = getStartClaimPayload(payload);

      requestPayload.request.raiseClaimRequest = requestPayload.request.createClaimRequest;

      delete requestPayload.request.createClaimRequest;
      delete requestPayload.request.raiseClaimRequest.consumerServiceRequest;

      Object.assign(requestPayload.request.raiseClaimRequest, {
        zipcode,
        state,
        productSubCategoryCode: productLine,
        issues: [selectedIssue],
      });

      return requestPayload;
    },
};

export default getters;
