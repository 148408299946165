import Vue from 'vue';
import Vuex from 'vuex';
import errorModal from '@/store/modules/errorModal';
import user from '@/store/modules/user';
import device from '@/store/modules/device';
import myDevices from '@/store/modules/myDevices';
import deviceInformation from '@/store/modules/deviceInformation';
import requestService from '@/store/modules/requestService';
import jobInfo from '@/store/modules/jobInfo';
import actionsDecorator from '@/store/actionsDecorator';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    errorModal,
    user,
    device,
    myDevices,
    deviceInformation,
    requestService,
    jobInfo,
  },
  state: {
    actionsDecorator,
  },
  actions: {
    setModalError({commit}, config) {
      commit('errorModal/SET_ERROR_MODAL_DATA', {
        title: config?.title || 'Sorry, something went wrong. It’s not you, it’s us',
        text: config?.text || [
          'Seems like we have a trouble loading this page.',
          'Please try again later or go back to previous page',
        ],
        callCenter: config?.callCenter || true,
        cta: config?.cta || [
          {
            text: 'Reload this page',
            variant: 'secondary',
            callback: () => window.location.reload(),
          },
          {
            text: 'Go back to previous page',
            callback: () => {
              commit('errorModal/CLEAR_ERROR_MODAL_DATA');
              history.back();
            },
          },
        ],
      });
    },
    setAuthenticationError({commit}, value) {
      commit('errorModal/SET_AUTH_ERROR_MODAL', value);
    },
  },
  strict: debug,
});
