export default (code) => {
  return [
    'a',
    'c',
    'd',
    'g',
    'n',
    'o',
    'p',
    'q',
    'u',
    'v',
    'w',
    'x',
    // 'active',
    // 'Cancelled',
    // 'Expired',
    // 'HOLD',
    // 'Inactive',
    // 'Renewal Pending',
    // 'Suspended',
  ].includes(code?.toLowerCase());
};
