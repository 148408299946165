<template>
  <div class="hs-select">
    <model-select v-bind="$attrs" v-on="$listeners" />
    <p
      v-if="error || warning"
      :class="{'hs-select__error--warning': warning}"
      class="hs-select__error"
    >
      {{ error || warning }}
    </p>
  </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import HSModelSelect from './HSModelSelect';

export default {
  inheritAttrs: false,
  components: {
    ModelSelect: HSModelSelect,
  },
  props: {
    error: String,
    warning: String,
  },
};
</script>

<style scoped lang="scss">
.hs-select {
  &__error {
    margin-top: 10px;
    color: $lighterRed;
    font-weight: 700;
    font-size: 16px;
    &--warning {
      color: #8d7146;
    }
  }
}
</style>

<style lang="scss">
.hs-select {
  .ui.selection.dropdown {
    font-size: 16px;
    line-height: 110%;
  }
  .ui.selection.dropdown:hover {
    border-color: $mediumGray;
  }
  .ui.selection.active.dropdown,
  .ui.selection.active.dropdown .menu {
    border-color: $mediumGray;
    box-shadow: none;
  }
  .ui.selection.active.dropdown:hover,
  .ui.selection.active.dropdown:hover .menu {
    border-color: $mediumGray;
    box-shadow: none;
  }
  .ui.search.dropdown {
    border-color: $mediumGray;
  }
  .ui.search.dropdown > .text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ui.dropdown > .dropdown.icon {
    background: url(~@/assets/images/combobox.svg) no-repeat 50%/11px 6px;
    &::before {
      display: none;
    }
  }
  .ui.selection.dropdown > .delete.icon,
  .ui.selection.dropdown > .dropdown.icon,
  .ui.selection.dropdown > .search.icon {
    width: 42px;
    height: 48px;
  }
  .ui.search.dropdown > input.search {
    height: 44px;
    font-size: 16px;
    line-height: 110%;
  }
  .ui.dropdown .menu > .item {
    padding: 5px 15px;
    color: $darkGray;
    font-weight: bold;
    font-size: 16px;
    line-height: 110%;
  }
}
</style>
