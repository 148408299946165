<template>
  <div class="hs-heading">
    <div class="hs-heading__title-wrapper">
      <h1 v-if="showTitle" class="hs-heading__title">
        <template v-if="$slots.default">
          <slot></slot>
        </template>
        <template v-else>
          {{ title }}
        </template>
      </h1>
      <slot name="title-elements"></slot>
    </div>
    <p v-if="showDescr" class="hs-heading__description">
      <template v-if="$slots.description">
        <slot name="description"></slot>
      </template>
      <template v-else>
        {{ description }}
      </template>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
  computed: {
    showTitle() {
      return this.$slots.default || this.title;
    },
    showDescr() {
      return this.$slots.description || this.description;
    },
  },
};
</script>

<style lang="scss" scoped>
.hs-heading {
  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include mf($gridMDBreakpoint) {
      margin-bottom: 9px;
    }
  }
  &__title {
    margin: 0;
    font-weight: 700;
    font-size: 20px;

    @include mf($gridMDBreakpoint) {
      font-size: 24px;
    }
  }
  &__description {
    margin: 0;
    color: $mediumGray;
    font-size: 14px;

    @include mf($gridMDBreakpoint) {
      max-width: 445px;
      font-size: 18px;
    }
  }
}
</style>
