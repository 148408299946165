var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"navigation-menu-list"},_vm._l((_vm.filteredMenu),function(item){return _c('li',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideAll),expression:"hideAll"}],key:item.name,staticClass:"navigation-menu-list__list-item",class:_vm.getListItemClasses(item)},[(!item.items)?[(item.to)?_c('router-link',{staticClass:"navigation-menu-list__link",class:{'navigation-menu-list__link--always-active': item.alwaysActive},attrs:{"to":item.to(_vm.$route)}},[_vm._v(_vm._s(_vm.getItemName(item)))]):_c('a',{staticClass:"navigation-menu-list__link",attrs:{"href":item.href}},[_vm._v(" "+_vm._s(_vm.getItemName(item))+" ")])]:[_c('button',{ref:((item.name) + "Button"),refInFor:true,staticClass:"navigation-menu-list__link navigation-menu-list__link--nested",class:{
          'navigation-menu-list__link--active': _vm.isOpen[item.name],
        },on:{"click":function($event){return _vm.toggle(item.name)}}},[(item.iconClass)?_c('div',{staticClass:"navigation-menu-list__icon",class:("navigation-menu-list__icon--" + (item.iconClass))}):_vm._e(),_vm._v(" "+_vm._s(_vm.getItemName(item))+" ")]),_c('ul',{ref:((item.name) + "List"),refInFor:true,staticClass:"navigation-menu-list__list--child",class:{
          'navigation-menu-list__list--hidden': !_vm.isOpen[item.name],
        }},_vm._l((_vm.getFilteredItems(item)),function(childItem){return _c('li',{key:childItem.name,staticClass:"navigation-menu-list__list-item navigation-menu-list__list-item--nested",class:{
            'navigation-menu-list__list-item--blue': childItem.blue,
          }},[_c('a',{attrs:{"href":childItem.href}},[_vm._v(_vm._s(childItem.name))])])}),0),(_vm.alwaysShowDetachedItems)?_vm._l((_vm.getDetachedItems(item)),function(childItem){return _c('div',{key:childItem.name},[_c('div',{staticClass:"`navigation-menu-list__list-item navigation-menu-list__list-item--nested navigation-menu-list__list-item--detached-nested`",class:{
              'navigation-menu-list__list-item--blue': childItem.blue,
            }},[_c('a',{attrs:{"href":childItem.href}},[_vm._v(_vm._s(childItem.name))])])])}):_vm._e()]],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }