<template>
  <hs-popup
    v-if="showAuthModal"
    :show-hide="false"
    aria-labelledby="auth-error-modal-titile"
    aria-describedby="auth-error-modal-desc"
    class="error-modal"
  >
    <div class="error-modal__icon"></div>
    <h1 class="hs-popup__title" id="auth-error-modal-titile">Not authorized</h1>
    <div class="hs-popup__description" id="auth-error-modal-desc">
      <p>Sorry, seems like your session has expired. Please Sign in again</p>
    </div>
    <div class="hs-popup__footer">
      <LoginButton>
        <hs-button size="sm">Sign in again</hs-button>
      </LoginButton>
    </div>
  </hs-popup>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import LoginButton from '@/components/shared/LoginButton';

export default {
  components: {
    LoginButton,
  },
  computed: {
    ...mapState('errorModal', ['showAuthModal']),
  },
  methods: {
    ...mapMutations('errorModal', ['CLEAR_ERROR_MODAL_DATA']),
    closeError() {
      this.CLEAR_ERROR_MODAL_DATA();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-modal {
  &__icon {
    width: 100px;
    height: 100px;
    margin: 15px auto;
    background-image: url('~@/assets/images/sad-icon.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    @include mf($gridMDBreakpoint) {
      width: 125px;
      height: 125px;
      margin: 35px auto 42px;
    }
  }
}
</style>
<style lang="scss">
.error-modal {
  .hs-popup {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include mf($gridMDBreakpoint) {
        flex-basis: 973px;
        max-width: 973px;
      }
    }
    &__title {
      margin-right: 0;
    }
    &__footer {
      align-self: stretch;
      *:only-child {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
</style>
