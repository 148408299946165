<template>
  <div>
    <p-checkbox
      :checked="innerChecked"
      :class="classes"
      class="hs-checkbox p-default p-svg p-curve p-fill"
      @change="handleChange"
    >
      <slot></slot>
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="svg"
      >
        <path d="M1 5.5L4.65217 9L13 1" stroke="white" stroke-width="2" />
      </svg>
    </p-checkbox>
  </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/check';

export default {
  components: {
    'p-checkbox': PrettyCheck,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    value: String,
    checked: [Boolean, Array],
    type: String,
    disabled: Boolean,
  },
  computed: {
    innerChecked() {
      if (Array.isArray(this.checked)) return this.checked.indexOf(this.value) !== -1;
      return this.checked;
    },
    classes() {
      const res = [];
      if (this.type) res.push(`hs-checkbox--type-${this.type}`);
      if (this.disabled) res.push('hs-checkbox--disabled');
      return res;
    },
  },
  methods: {
    handleChange(checked) {
      let res = checked;
      if (Array.isArray(this.checked)) {
        const arrCopy = [...this.checked];
        checked ? arrCopy.push(this.value) : arrCopy.splice(arrCopy.indexOf(this.value), 1);
        res = arrCopy;
      }
      this.$emit('change', res);
    },
  },
};
</script>

<style lang="scss">
@import '~pretty-checkbox/src/pretty-checkbox.scss';
$ckbxBorderColor: #d1d7db;
$ckbxDisabledBGColor: #eff0f1;
$ckbxBorderRadius: 4px;
$ckbxDimensions: 16px;

.hs-checkbox {
  &.pretty.p-svg .state .svg {
    top: 0;
    width: 1em;
    height: 1em;
  }
  &.pretty .state label::before,
  &.pretty .state label::after {
    top: 0;
    width: $ckbxDimensions;
    height: $ckbxDimensions;
  }
  &.pretty.p-curve .state label::before,
  &.pretty.p-curve .state label::after {
    border-radius: $ckbxBorderRadius;
  }
  &--disabled {
    pointer-events: none;
    &.pretty.p-curve .state label::before {
      z-index: 1;
    }
    &.pretty.p-curve .state label::before,
    &.pretty.p-curve .state label::after {
      background-color: $ckbxDisabledBGColor !important;
    }
  }
  &.pretty.pretty.p-default input:checked ~ .state label::after {
    background-color: $blue !important;
  }
  &--type-red.pretty.pretty.p-default input:checked ~ .state label::after {
    background-color: $primaryRed !important;
  }
}
</style>
