import Vue from 'vue';

export default () => {
  return new Vue({
    data: {
      finalCalendarResult: {
        calendar: '',
        currentYear: '',
        currentMonthName: '',
      },
    },
    methods: {
      getMonthName(month) {
        // Method returns name of specified month (parameter)
        return [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ][month];
      },
      leapYear(year) {
        return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
      },
      getDays(month, year) {
        // create array to hold number of days in each month
        const ar = new Array(12);
        ar[0] = 31; // January
        ar[1] = this.leapYear(year) ? 29 : 28; // February
        ar[2] = 31; // March
        ar[3] = 30; // April
        ar[4] = 31; // May
        ar[5] = 30; // June
        ar[6] = 31; // July
        ar[7] = 31; // August
        ar[8] = 30; // September
        ar[9] = 31; // October
        ar[10] = 30; // November
        ar[11] = 31; // December

        // return number of days in the specified month (parameter)
        return ar[month];
      },
      setCal(month, year) {
        this.finalCalendarResult = {
          calendar: '',
          currentYear: '',
          currentMonthName: '',
        };
        let indicator = true;
        const dateInst = new Date(year, month, 1);
        const currentDate = new Date();
        const firstDay = dateInst.getDay() + 1;
        const lastDate = this.getDays(month, year);
        let digit = 1;
        let prevMonthDaysCount = 1;
        let nextMonthDate = 1;
        let prevMonthDate = this.getDays(month - 1, year) - firstDay + 2;
        let dateInCurrentCell;
        const datesArray = [];

        if (month === 0) {
          prevMonthDate = this.getDays(11, year - 1) - firstDay + 2;
        }

        while (indicator) {
          const iterationArr = [];
          for (let j = 0; j < 7; j += 1) {
            const currentIterObj = {};

            const gettedYear = year;
            let gettedMonth = month + 1;
            if (gettedMonth < 10) {
              gettedMonth = '0' + gettedMonth;
            }
            let day = digit;
            if (day < 10) {
              day = '0' + day;
            }
            const dataAttributeDate = [gettedYear, gettedMonth, day].join('-');

            if (digit > lastDate) {
              if (j == 0) {
                indicator = false;
                break;
              }
              indicator = false;
              currentIterObj.nextMonth = true;
              currentIterObj.date = nextMonthDate;
              currentIterObj.formatedDate = `${year}-${
                String((+month + 2) % 12).length > 1 ? (+month + 2) % 12 : '0' + ((+month + 2) % 12)
              }-${String(nextMonthDate).length > 1 ? nextMonthDate : '0' + nextMonthDate}`;
              nextMonthDate += 1;
              iterationArr.push(currentIterObj);
            } else {
              if (prevMonthDaysCount < firstDay) {
                let prevMonth = +month - 1;
                let prevMonthYear = year;
                if (month === 0) {
                  prevMonth = 12;
                  prevMonthYear = year - 1;
                }
                currentIterObj.prevMonth = true;
                currentIterObj.date = prevMonthDate;
                currentIterObj.formatedDate = `${prevMonthYear}-${
                  String(prevMonth).length > 1 ? prevMonth : '0' + prevMonth
                }-${String(prevMonthDate).length > 1 ? prevMonthDate : '0' + prevMonthDate}`;
                prevMonthDate += 1;
                prevMonthDaysCount += 1;
              } else {
                dateInCurrentCell = new Date(year, month, digit, 23, 59);

                if (dateInCurrentCell < currentDate) {
                  currentIterObj.pastDay = true;
                }
                if (
                  dateInCurrentCell.getDate() == currentDate.getDate() &&
                  dateInCurrentCell.getMonth() == currentDate.getMonth() &&
                  dateInCurrentCell.getYear() == currentDate.getYear()
                ) {
                  currentIterObj.today = true;
                }
                currentIterObj.date = digit;
                currentIterObj.formatedDate = `${year}-${
                  String(+month + 1).length > 1 ? +month + 1 : '0' + (+month + 1)
                }-${String(digit).length > 1 ? digit : '0' + digit}`;
                digit += 1;
              }
              iterationArr.push(currentIterObj);
            }
          }
          if (iterationArr.length) {
            datesArray.push(iterationArr);
          }
        }

        this.finalCalendarResult.calendar = datesArray;
        this.finalCalendarResult.currentYear = year;
        this.finalCalendarResult.currentMonthName = this.getMonthName(month);

        return this.finalCalendarResult;
      },
    },
  });
};
