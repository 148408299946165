<template>
  <nav class="navigation" role="navigation">
    <NavigationMenuList />
  </nav>
</template>
<script>
import NavigationMenuList from '@/components/header/NavigationMenuList';
export default {
  components: {
    NavigationMenuList,
  },
};
</script>
