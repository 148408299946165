import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {domain, clientId, audience} from '@/libs/auth/config';
import getAuthorizationToken from '@/utils/getAuthorizationToken';
import HSDSPlugin from '@/libs/design-system';
import {Auth0Plugin} from '@/libs/auth/index';
import VueMeta from 'vue-meta';
import WrapperWithBack from '@/components/shared/WrapperWithBackBtn';
import WrapperWithCloseBtn from '@/components/shared/WrapperWithCloseBtn';
import VueContentPlaceholders from 'vue-content-placeholders';
import Toasted from 'vue-toasted';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';

const VUE_APP_SENTRY_DSN =
  process.env.VUE_APP_SENTRY_DSN ||
  'https://06642244ab77492898e8f4ea08766ce4@o4504670517395456.ingest.sentry.io/4504670518640640';

Sentry.init({
  Vue,
  dsn: VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', process.env.VUE_APP_OSS_BASE, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});
Vue.use(HSDSPlugin);
Vue.use(VueMeta);
// if (process.env.NODE_ENV === 'development') {
//   const VueAxe = require('vue-axe').default;
//   Vue.use(VueAxe, {
//     runOptions: {
//       rules: {
//         'color-contrast': {enabled: false},
//       },
//     },
//   });
// }
Vue.component('wrapper-with-back', WrapperWithBack);
Vue.component('wrapper-with-close-btn', WrapperWithCloseBtn);
Vue.use(VueContentPlaceholders);
Vue.use(Toasted, {
  duration: 10000,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  async created() {
    // ensure that auth0 watcher is initialised at least once
    // at all times
    try {
      const token = await getAuthorizationToken();
      if (token) {
        this.$auth.isAuthenticated = true;
      }
    } catch (e) {
      this.$router.replace('/');
    }
  },
}).$mount('#app');
