export const statesList = [
  {
    text: 'Alabama',
    value: 'Alabama',
    code: 'AL',
  },
  {
    text: 'Alaska',
    value: 'Alaska',
    code: 'AK',
  },
  {
    text: 'American Samoa',
    value: 'American Samoa',
    code: 'AS',
  },
  {
    text: 'Arizona',
    value: 'Arizona',
    code: 'AZ',
  },
  {
    text: 'Arkansas',
    value: 'Arkansas',
    code: 'AR',
  },
  {
    text: 'California',
    value: 'California',
    code: 'CA',
  },
  {
    text: 'Colorado',
    value: 'Colorado',
    code: 'CO',
  },
  {
    text: 'Connecticut',
    value: 'Connecticut',
    code: 'CT',
  },
  {
    text: 'Delaware',
    value: 'Delaware',
    code: 'DE',
  },
  {
    text: 'District Of Columbia',
    value: 'District Of Columbia',
    code: 'DC',
  },
  {
    text: 'Federated States Of Micronesia',
    value: 'Federated States Of Micronesia',
    code: 'FM',
  },
  {
    text: 'Florida',
    value: 'Florida',
    code: 'FL',
  },
  {
    text: 'Georgia',
    value: 'Georgia',
    code: 'GA',
  },
  {
    text: 'Guam',
    value: 'Guam',
    code: 'GU',
  },
  {
    text: 'Hawaii',
    value: 'Hawaii',
    code: 'HI',
  },
  {
    text: 'Idaho',
    value: 'Idaho',
    code: 'ID',
  },
  {
    text: 'Illinois',
    value: 'Illinois',
    code: 'IL',
  },
  {
    text: 'Indiana',
    value: 'Indiana',
    code: 'IN',
  },
  {
    text: 'Iowa',
    value: 'Iowa',
    code: 'IA',
  },
  {
    text: 'Kansas',
    value: 'Kansas',
    code: 'KS',
  },
  {
    text: 'Kentucky',
    value: 'Kentucky',
    code: 'KY',
  },
  {
    text: 'Louisiana',
    value: 'Louisiana',
    code: 'LA',
  },
  {
    text: 'Maine',
    value: 'Maine',
    code: 'ME',
  },
  {
    text: 'Marshall Islands',
    value: 'Marshall Islands',
    code: 'MH',
  },
  {
    text: 'Maryland',
    value: 'Maryland',
    code: 'MD',
  },
  {
    text: 'Massachusetts',
    value: 'Massachusetts',
    code: 'MA',
  },
  {
    text: 'Michigan',
    value: 'Michigan',
    code: 'MI',
  },
  {
    text: 'Minnesota',
    value: 'Minnesota',
    code: 'MN',
  },
  {
    text: 'Mississippi',
    value: 'Mississippi',
    code: 'MS',
  },
  {
    text: 'Missouri',
    value: 'Missouri',
    code: 'MO',
  },
  {
    text: 'Montana',
    value: 'Montana',
    code: 'MT',
  },
  {
    text: 'Nebraska',
    value: 'Nebraska',
    code: 'NE',
  },
  {
    text: 'Nevada',
    value: 'Nevada',
    code: 'NV',
  },
  {
    text: 'New Hampshire',
    value: 'New Hampshire',
    code: 'NH',
  },
  {
    text: 'New Jersey',
    value: 'New Jersey',
    code: 'NJ',
  },
  {
    text: 'New Mexico',
    value: 'New Mexico',
    code: 'NM',
  },
  {
    text: 'New York',
    value: 'New York',
    code: 'NY',
  },
  {
    text: 'North Carolina',
    value: 'North Carolina',
    code: 'NC',
  },
  {
    text: 'North Dakota',
    value: 'North Dakota',
    code: 'ND',
  },
  {
    text: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    text: 'Ohio',
    value: 'Ohio',
    code: 'OH',
  },
  {
    text: 'Oklahoma',
    value: 'Oklahoma',
    code: 'OK',
  },
  {
    text: 'Oregon',
    value: 'Oregon',
    code: 'OR',
  },
  {
    text: 'Palau',
    value: 'Palau',
    code: 'PW',
  },
  {
    text: 'Pennsylvania',
    value: 'Pennsylvania',
    code: 'PA',
  },
  {
    text: 'Puerto Rico',
    value: 'Puerto Rico',
    code: 'PR',
  },
  {
    text: 'Rhode Island',
    value: 'Rhode Island',
    code: 'RI',
  },
  {
    text: 'South Carolina',
    value: 'South Carolina',
    code: 'SC',
  },
  {
    text: 'South Dakota',
    value: 'South Dakota',
    code: 'SD',
  },
  {
    text: 'Tennessee',
    value: 'Tennessee',
    code: 'TN',
  },
  {
    text: 'Texas',
    value: 'Texas',
    code: 'TX',
  },
  {
    text: 'Utah',
    value: 'Utah',
    code: 'UT',
  },
  {
    text: 'Vermont',
    value: 'Vermont',
    code: 'VT',
  },
  {
    text: 'Virgin Islands',
    value: 'Virgin Islands',
    code: 'VI',
  },
  {
    text: 'Virginia',
    value: 'Virginia',
    code: 'VA',
  },
  {
    text: 'Washington',
    value: 'Washington',
    code: 'WA',
  },
  {
    text: 'West Virginia',
    value: 'West Virginia',
    code: 'WV',
  },
  {
    text: 'Wisconsin',
    value: 'Wisconsin',
    code: 'WI',
  },
  {
    text: 'Wyoming',
    value: 'Wyoming',
    code: 'WY',
  },
];

export const statesCodeIndex = {};
export const statesNameIndex = {};

for (const data of statesList) {
  const {value, code} = data;

  statesCodeIndex[code.toUpperCase()] = data;
  statesNameIndex[value.toUpperCase()] = data;
}

export function getStateInfo(stateCodeOrName) {
  if (!stateCodeOrName) {
    return null;
  }
  return (
    statesCodeIndex[stateCodeOrName.toUpperCase()] || statesNameIndex[stateCodeOrName.toUpperCase()]
  );
}

export function getStateCode(stateCodeOrName) {
  return getStateInfo(stateCodeOrName)?.code;
}

export function getStateName(stateCodeOrName) {
  return getStateInfo(stateCodeOrName)?.value;
}

export function compareStates(...stateCodesOrNames) {
  const codes = stateCodesOrNames.map(getStateCode);
  return codes.every((code) => code === codes[0]);
}

export default statesList;
