import {isProd} from '@/constants/env';
import {API} from '@/constants/api';
import {getRequestBody} from '@/utils/request';
import generateGuardedAsyncActions from '@/store/generateGuardedAsyncActions';

const guardedActions = generateGuardedAsyncActions({
  async UPDATE_SERVIFY_REQUEST_DETAILS(context, {claim, params}) {
    const user = context.rootGetters['user/fullName'];

    const {serviceJobID, claimsMasterId} = claim;
    const payload = getRequestBody({
      requestName: 'updateRequestDetailsRequest',
      requestParams: {
        referenceID: serviceJobID,
        countryCode: 'US',
        externalReferenceID: claimsMasterId,
        consumerServiceRequest: params,
      },
      user,
    });

    const {data} = await context.rootState.actionsDecorator({
      store: context,
      path: API.CLAIMS.UPDATE_SERVIFY_REQUEST_DETAILS,
      method: 'post',
      payload,
    });

    const updatedSuccessfully = data?.response?.outcome?.resultDescription === 'Success';

    return updatedSuccessfully;
  },
});

const state = {
  ...guardedActions.state,
  serviceTypeCode: 'CLAIM_CARRYIN',
  zip: null,
  chosenLocation: '',
  availableDates: null,
  slots: null,
  chosenDate: '',
};

const getters = {
  service: ({serviceTypeCode}, getters, rootState, rootGetters) =>
    rootGetters['requestService/availableServices'][serviceTypeCode],
  locations: (_, {service}, rootState, rootGetters) => {
    let locations = [];

    const serviceLocation = service?.serviceLocation;
    if (serviceLocation) {
      const from = rootGetters['requestService/fullSelectedAddressText'];

      locations = serviceLocation.map((location) => ({
        id: location.serviceLocationCode,
        text: location.serviceLocationName,
        distance: 'Click to check', // TODO: Fix distance.
        from,
        to: location.address,
      }));
    }

    return locations;
  },
  locationInfo: ({chosenLocation}, {locations}) =>
    locations.find((location) => location.id === chosenLocation),
};

const actions = {
  ...guardedActions.actions,
  ['GET_ZIP']: (store) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GEL_ZIP,
          method: 'get',
        })
        .then((res) => {
          store.commit('SET_ZIP', res.data?.zip);
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_LOCATIONS']: (store, zip) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GEL_CARRY_IN_LOCATIONS,
          method: 'get',
          payload: {
            params: {
              zip,
            },
          },
        })
        .then((res) => {
          store.commit('SET_LOCATIONS', res.data);
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_AVAILABLE_DATES']: (store, location) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GEL_AVAILABLE_DATES,
          method: 'get',
          payload: {
            params: {
              location,
            },
          },
        })
        .then((res) => {
          store.commit('SET_AVAILABLE_DATES', res.data);
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_SLOTS']: async (context, {zipCode, serviceProviderAccount}) => {
    const {serviceTypeCode, chosenLocation} = context.state;
    const {model, make, productLine} = context.rootState.deviceInformation;

    const serviceLocationCode = chosenLocation || serviceProviderAccount;

    const payload = getRequestBody({
      requestName: 'getSlotlistRequest',
      requestParams: {
        serviceTypeCode,
        zipCode,
        serviceLocationCode,
        countryCode: 'US',

        productCode: model,
        brandCode: make,
        productSubCategoryCode: productLine,
      },
    });

    const {data} = await context.rootState.actionsDecorator({
      store: context,
      path: API.CLAIMS.GET_SLOTS,
      method: 'post',
      payload,
    });

    const slots = data?.response?.getSlotlistResponse?.availableSlots;
    context.commit('SET_SLOTS', slots);

    return slots;
  },
};

const mutations = {
  ...guardedActions.mutations,
  ['SET_ZIP']: (state, zip) => {
    state.zip = zip || '';
  },
  ['SET_LOCATIONS']: (state, data) => {
    state.locations = data || [];
  },
  ['SET_SLOTS']: (state, data) => {
    state.slots = data || [];
  },
  ['SET_CHOSEN_LOCATION']: (state, value) => {
    state.chosenLocation = value || '';
  },
  ['SET_AVAILABLE_DATES']: (state, data) => {
    state.availableDates = data || [];
  },
  ['SET_CHOSEN_DATE']: (state, value) => {
    state.chosenDate = value || '';
  },
  ['CLEAR_STORE']: (state) => {
    state.locations = null;
    state.zip = null;
    state.chosenLocation = '';
    state.availableDates = null;
    state.chosenDate = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
