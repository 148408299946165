<template>
  <!-- TODO: adjust to use confirm-button-text, decline-button-text and @confirm @decline -->
  <hs-popup
    v-if="showModal"
    :show-hide="false"
    aria-labelledby="error-modal-title"
    aria-describedby="error-modal-desc"
    class="error-modal"
  >
    <div class="error-modal__icon"></div>
    <h1 class="hs-popup__title" id="error-modal-title">{{ title }}</h1>
    <div v-if="text && text.length" class="hs-popup__description" id="error-modal-desc">
      <p v-for="t in text" :key="t">{{ t }}</p>
    </div>
    <div v-if="callCenter" class="error-modal__call-center">
      <p>If any questions, please contact our call center</p>
      <a :href="phones.contactCenterTel">{{ phones.contactCenter }}</a>
    </div>
    <div v-if="cta && cta.length" class="hs-popup__footer">
      <hs-button
        v-for="item in cta"
        :key="item.text"
        :variant="item.variant"
        size="sm"
        @click="item.callback"
        >{{ item.text }}</hs-button
      >
    </div>
  </hs-popup>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import phones from '@/constants/phones';

export default {
  computed: {
    ...mapState('errorModal', ['showModal', 'title', 'text', 'callCenter', 'cta']),
    phones: () => phones,
  },
  methods: {
    ...mapMutations('errorModal', ['CLEAR_ERROR_MODAL_DATA']),
    closeError() {
      this.CLEAR_ERROR_MODAL_DATA();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-modal {
  &__icon {
    width: 100px;
    height: 100px;
    margin: 15px auto;
    background-image: url('~@/assets/images/sad-icon.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    @include mf($gridMDBreakpoint) {
      width: 125px;
      height: 125px;
      margin: 35px auto 42px;
    }
  }
  &__call-center {
    max-width: 512px;
    margin-top: 40px;
    padding: 18px 20px;
    font-size: 22px;
    text-align: center;
    background: #fbfbfb;
    border: 1px dashed #d1d1d1;
    border-radius: 4px;
  }
}
</style>
<style lang="scss">
.error-modal {
  .hs-popup {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include mf($gridMDBreakpoint) {
        flex-basis: 973px;
        max-width: 973px;
      }
    }
    &__title {
      margin-right: 0;
    }
    &__footer {
      align-self: stretch;
      *:only-child {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
</style>
