<template>
  <div class="hs-btn" @click="emitClick" @keydown.space="emitSpace" @keydown.enter="emitEnter">
    <router-link
      v-if="route"
      :to="link"
      :target="openNewTab ? '_blank' : '_self'"
      :class="classes"
      class="hs-btn__button"
    >
      <span class="hs-btn__content-cont">
        <slot></slot>
        <slot name="icon"></slot>
      </span>
    </router-link>
    <a
      v-else-if="link"
      :href="link"
      :target="openNewTab ? '_blank' : '_self'"
      :class="classes"
      class="hs-btn__button"
    >
      <span class="hs-btn__content-cont">
        <slot></slot>
        <slot name="icon"></slot>
      </span>
    </a>
    <button v-else :type="type" :class="classes" class="hs-btn__button">
      <span class="hs-btn__content-cont">
        <slot></slot>
        <slot name="icon"></slot>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    variant: String,
    size: String,
    route: Boolean,
    link: [String, Object],
    openNewTab: Boolean,
    reverse: Boolean,
    disabled: Boolean,
    notBold: Boolean,
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      const res = [];
      if (this.variant) res.push(`hs-btn__button--variant-${this.variant}`);
      if (this.size) res.push(`hs-btn__button--size-${this.size}`);
      if (this.reverse) res.push('hs-btn__button--reverse');
      if (this.disabled) res.push('hs-btn__button--disabled');
      if (this.notBold) res.push('hs-btn__button--not-bold');
      return res;
    },
  },
  methods: {
    emitClick() {
      if (!this.disabled) this.$emit('click');
    },
    emitSpace() {
      if (!this.disabled) {
        this.$emit(
          'keydown',
          new KeyboardEvent('keydown', {
            keyCode: 32,
          })
        );
      }
    },
    emitEnter() {
      if (!this.disabled) {
        this.$emit(
          'keydown',
          new KeyboardEvent('keydown', {
            keyCode: 13,
          })
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$btnBorderRadius: 4px;
$btnFontSize: 18px;
$btnLargeHeight: 56px;
$btnMediumHeight: 48px;
$btnSmallHeight: 44px;
$btnXSmallHeight: 36px;
$btnLargePadding: 30px;
$btnMediumPadding: 24px;
$btnSmallPadding: 16px;
$btnXSmallPadding: 12px;

.hs-btn {
  display: inline-block;
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $btnLargeHeight;
    padding: 0 $btnLargePadding;
    overflow: hidden;
    color: $white;
    font-weight: 700;
    font-size: $btnFontSize;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    background-color: $blue;
    border: 0;
    border-radius: $btnBorderRadius;
    cursor: pointer;

    &:hover {
      background-color: $darkBlue;
    }
    &--size-md {
      height: $btnMediumHeight;
      padding: 0 $btnMediumPadding;
    }
    &--size-sm {
      height: $btnSmallHeight;
      padding: 0 $btnSmallPadding;
    }
    &--size-xs {
      height: $btnXSmallHeight;
      padding: 0 $btnXSmallPadding;
    }
    &--variant-red {
      background-color: $primaryRed;
      &:hover {
        background-color: $darkRed;
      }
    }
    &--variant-secondary {
      color: $blue;
      background-color: $white;
      box-shadow: inset 0 0 0 2px $blue;
      &:hover {
        color: $darkBlue;
        background-color: $white;
        box-shadow: inset 0 0 0 2px $darkBlue;
      }
    }
    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &--not-bold {
      font-weight: normal;
    }
    &__content-cont {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &--reverse &__content-cont {
      flex-direction: row-reverse;
    }
  }
}
</style>
