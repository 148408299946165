import {API} from '@/constants/api';
import {getRequestBody} from '@/utils/request';
import generateGuardedAsyncActions from '@/store/generateGuardedAsyncActions';

const guardedActions = generateGuardedAsyncActions({
  ['SAVE_DEVICE']: async (store, payload) => {
    const {customerRef} = await store.dispatch(
      'user/DECRYPT_PARAMS',
      {
        customerRef: payload.customerRef,
      },
      {root: true}
    );

    return new Promise(async (resolve, reject) => {
      let requestCode = '';
      let isOtherModel = false;
      let isEligAddEquimnt = false;
      if (store.state.otherBrand || store.state.otherModel) {
        isOtherModel = true;
        requestCode = await store.rootState.actionsDecorator({
          store,
          path: API.DEVICE.PROCESS_UNREGISTERED_DEVICE,
          method: 'post',
          payload: getRequestBody({
            requestName: 'getUnregisteredProductCodeRequest',
            requestParams: {
              productSubCategoryCode: store.state.type,
              countryCode: 'US',
              brandName: store.state.otherBrand || store.state.brand,
              productName: store.state.otherModel,
            },
          }),
        });
      }
      const rootAddress = store.rootState.user?.customerDetails;

      const addressDetail = {
        HouseNo: rootAddress.houseNo,
        AddressLine2: rootAddress.addressLine1,
        State: rootAddress.addressLine5,
        ZipCode: rootAddress.zipCode,
        Country: rootAddress.country,
        City: rootAddress.addressLine4,
      };

      let streetAddress = '';
      let apartmentUnitNumber = '';
      if (addressDetail) {
        const {AddressLine2, FlatNo} = addressDetail;
        streetAddress = `${AddressLine2}`.trim();
        apartmentUnitNumber = FlatNo;
      }
      if (isOtherModel) {
        if (
          requestCode?.data?.response?.getUnregisteredProductCodeResponse
            ?.unregisteredProductCode &&
          requestCode?.data?.response?.getUnregisteredProductCodeResponse
            ?.unregisteredProductCode != ''
        ) {
          isEligAddEquimnt = true;
        } else {
          isEligAddEquimnt = false;
        }
      } else {
        isEligAddEquimnt = true;
      }
      if (isEligAddEquimnt) {
        const payload = getRequestBody({
          requestName: 'addUserEquipmentRequest',
          requestParams: {
            customerRef,
            productLine: store.state.type,
            // TODO: For some reason Add Device API doesn't work without it.
            postalCode: addressDetail?.ZipCode || '00000',
            streetAddress,
            apartmentUnitNumber,
            equipmentDetails: {
              make: store.state.otherBrand || store.state.brand,
              makeName: store.state.otherBrand || store.state.brand,
              model: store.state.otherModel || store.state.model,
              modelName:
                store.state.otherModel ||
                store.state.models.find((i) => i.id === store.state.model)?.title,
              serialNumber: store.state.sn,
              unregisteredProductCode:
                requestCode?.data?.response?.getUnregisteredProductCodeResponse
                  ?.unregisteredProductCode,
              equipmentName: store.state.nickname,
              installDate: new Date().toJSON(),
            },
          },
        });

        store.rootState
          .actionsDecorator({
            store,
            path: API.DEVICE.SAVE,
            method: 'post',
            payload,
          })
          .then((res) => {
            if (
              res?.data?.response?.addUserEquipmentResponse?.errorDetails?.errorDetail
                ?.errorCode === '200'
            ) {
              reject(
                res?.data?.response?.addUserEquipmentResponse?.errorDetails?.errorDetail
                  ?.errorDescription
              );
            } else {
              resolve(res);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        reject(
          requestCode?.data?.response?.getUnregisteredProductCodeResponse?.errorDetails?.errorDetail
            ?.errorDescription
        );
      }
    });
  },
});

export const state = {
  ...guardedActions.state,
  types: [],
  brands: [],
  models: [],
  type: '',
  brand: '',
  model: '',
  nickname: '',
  sn: '',
  otherBrand: '',
  otherModel: '',
};

export const getters = {
  models: (state) => {
    const res =
      state.models?.map((m) => {
        return {
          value: m.id,
          text: m.title,
        };
      }) || [];
    res.push({
      value: 'other',
      text: 'Other (model not listed)',
    });
    return res;
  },
  brands: (state) => {
    const res =
      state?.brands.map((b) => {
        return {
          value: b.id,
          text: b.title,
        };
      }) || [];
    res.push({
      value: 'other',
      text: 'Other (brand not listed)',
    });
    return res;
  },
  types: (state) => {
    return (
      state.types?.map((t) => {
        return {
          value: t.subCategoryCode,
          text: t.productLine,
        };
      }) || []
    );
  },
};

const actions = {
  ...guardedActions.actions,
  ['GET_DEVICE_TYPES']: (store, contracts) => {
    const payload = getRequestBody({
      requestName: 'getCEProductsRequest',
      requestParams: {
        productCodes: {
          productCode: contracts,
        },
        partnerCode: 'HS',
        state: 'OH',
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.GET_TYPES,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit(
            'SET_DEVICE_TYPES',
            res?.data?.response?.getCEProductsResponse?.productDetails
          );
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_DEVICE_BRANDS']: (store, type) => {
    const payload = getRequestBody({
      requestName: 'getBrandListRequest',
      requestParams: {
        productSubCategoryCode: type,
        countryCode: 'US',
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.GET_BRANDS,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit(
            'SET_DEVICE_BRANDS',
            res.data.response.getBrandListResponse.brandLists.brandList.map((b) => {
              return {
                id: b.brandCode,
                title: b.brandName,
              };
            })
          );
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_DEVICE_MODELS']: (store, {type, brand}) => {
    const payload = getRequestBody({
      requestName: 'getProductListRequest',
      requestParams: {
        productSubCategoryCode: type,
        brandCode: brand,
        countryCode: 'US',
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.GET_MODELS,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit(
            'SET_DEVICE_MODELS',
            res.data.response.getProductListResponse.productLists.productList.map((b) => {
              return {
                id: b.productCode,
                title: b.productName,
              };
            })
          );
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['REMOVE_DEVICE']: async (store, {id, productLine, customerRef}) => {
    const payload = getRequestBody({
      requestName: 'inactivateEquipmentRequest',
      requestParams: {
        customerRef,
        productLine,
        equipmentId: id,
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.REMOVE,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  ...guardedActions.mutations,
  ['SET_DEVICE_TYPES']: (state, types) => {
    state.types = types;
  },
  ['SET_DEVICE_BRANDS']: (state, brands) => {
    state.brands = brands;
  },
  ['SET_DEVICE_MODELS']: (state, models) => {
    state.models = models;
  },
  ['SET_DEVICE_TYPE']: (state, type) => {
    state.type = type;
  },
  ['SET_DEVICE_BRAND']: (state, brand) => {
    state.brand = brand;
  },
  ['SET_OTHER_BRAND']: (state, brand) => {
    state.otherBrand = brand;
  },
  ['SET_DEVICE_MODEL']: (state, model) => {
    state.model = model;
  },
  ['SET_OTHER_MODEL']: (state, model) => {
    state.otherModel = model;
  },
  ['SET_NICKNAME']: (state, nickname) => {
    state.nickname = nickname;
  },
  ['SET_SN']: (state, sn) => {
    state.sn = sn;
  },
  ['CLEAR_STORE']: (state) => {
    state.types = [];
    state.type = '';
    state.brands = [];
    state.brand = '';
    state.models = [];
    state.model = '';
    state.nickname = '';
    state.sn = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
