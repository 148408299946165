<template>
  <div>
    <vfc-input
      type="textarea"
      :rows="rows"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="classes"
      class="hs-input"
      @input="handleInput"
    >
      <slot></slot>
    </vfc-input>
    <p
      v-if="error || warning"
      :class="{'hs-input__error--warning': warning}"
      class="hs-input__error"
    >
      {{ error || warning }}
    </p>
  </div>
</template>

<script>
import 'vfc/dist/vfc.css';
import {Input} from 'vfc';

export default {
  components: {
    'vfc-input': Input,
  },
  props: {
    value: String,
    type: String,
    disabled: Boolean,
    autocomplete: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
    placeholder: String,
    error: String,
    warning: String,
    rows: Number,
  },
  computed: {
    classes() {
      const res = [];
      if (this.type) res.push(`hs-input--type-${this.type}`);
      if (this.disabled) res.push('hs-input--disabled');
      return res;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
$inputPlaceholderColor: #a6a6a6;
$inputDisabledBGColor: #eff0f1;
$inputBorderRadius: 4px;
$inputLineHeight: 18px;
$inputInnerPadding: 16px;
$inputFontSize: 16px;

.hs-input {
  .vue-textarea__inner {
    display: block;
    width: 100%;
    padding: $inputLineHeight $inputInnerPadding;
    color: $black;
    font-size: $inputFontSize;
    line-height: $inputLineHeight;
    border-color: $mediumGray;
    border-radius: $inputBorderRadius;
    &::placeholder {
      color: $inputPlaceholderColor;
    }
  }
  &--type-bottom-border .vue-textarea__inner {
    padding: 0;
    border: 0;
    border-bottom: 1px solid $blue;
    border-radius: 0;
  }
  &__error {
    margin-top: 10px;
    color: $lighterRed;
    font-weight: 700;
    font-size: 16px;
    &--warning {
      color: #8d7146;
    }
  }
}
.details__field {
  .hs-input {
    .vue-textarea__inner {
      font-size: 14px;
    }
  }
}
</style>
