<template>
  <article class="hs-datepicker__wrapper">
    <section
      :class="{
        'is-active': isSelectOpened,
        'is-disabled': disabled,
        'is-with-error': errors && errors.length,
      }"
      class="hs-datepicker"
    >
      <input v-model="hiddenInputValue" class="hs-datepicker__hidden-input" hidden type="hidden" />
      <button
        :title="computedValueText"
        :class="{'hs-datepicker__value--placeholder': !value}"
        type="button"
        class="hs-datepicker__value"
        ref="hsDatepicker"
        @click="showCalendar"
      >
        {{ computedValueText }}
      </button>
      <month-calendar
        :active-date="calendarActiveDate"
        :start-calendar-from="startCalendarFrom"
        :type="calendarType"
        :available-dates="availableDates"
        class="hs-datepicker__calendar"
        @changeday="catchCalendarChange"
      >
        <div class="hs-datepicker__action-btns-cont">
          <button
            class="hs-datepicker__cancel-btn"
            @click.stop="isSelectOpened = false"
            type="button"
          >
            CANCEL
          </button>
          <button class="hs-datepicker__apply-btn" @click="applyNewValue" type="button">
            {{ applyBtnText }}
          </button>
        </div>
      </month-calendar>
    </section>
    <div v-if="errors && errors.length" class="hs-datepicker__errors-cont">
      <span
        v-for="(error, index) in computedErrors"
        :key="error"
        v-html="`${index + 1}. ${error}`"
      ></span>
    </div>
  </article>
</template>

<script>
import monthCalendar from './HSMonthCalendar';

export default {
  components: {
    'month-calendar': monthCalendar,
  },
  props: {
    value: {
      type: String, // date-format YYYY-MM-DD
      validator: (value) => !value || value.indexOf('-') != -1,
    },
    placeholder: {type: String}, // no-select message
    calendarType: {type: String},
    applyButtonText: {type: String},
    displayText: {type: String},
    disabled: {type: Boolean},
    startCalendarFrom: {type: String},
    availableDates: {type: Array},
    errors: {type: Array},
  },
  data() {
    return {
      isSelectOpened: false,
      chosenValue: '',
      hiddenInputValue: '',
    };
  },
  computed: {
    computedErrors() {
      return this.errors?.filter((i) => !!i);
    },
    computedValueText() {
      return this.displayText || this.chosenValue || this.placeholder;
    },
    calendarActiveDate() {
      return this.value === this.hiddenInputValue ? this.value : this.hiddenInputValue || ''; // set date in month-calendar in format YYYY-MM-DD !
    },
    applyBtnText() {
      return this.applyButtonText || 'APPLY';
    },
  },
  watch: {
    value() {
      this.setChosenValue();
    },
    isSelectOpened() {
      this.$emit('isopened', this.isSelectOpened);
      this.hiddenInputValue = this.value;
    },
  },
  methods: {
    showCalendar() {
      if (this.disabled) return false;
      this.isSelectOpened = !this.isSelectOpened;
    },
    setChosenValue() {
      // visual datepicker format MM/DD/YYYY
      if (this.value) {
        const valArr = this.value.split('-');
        this.hiddenInputValue = this.value;
        this.chosenValue = `${valArr[1]}/${valArr[2]}/${valArr[0]}`;
      }
    },
    catchCalendarChange(value) {
      this.hiddenInputValue = value;
    },
    applyNewValue() {
      if (this.hiddenInputValue) {
        const valueArray = this.hiddenInputValue.split('-');
        this.chosenValue = `${valueArray[1]}/${valueArray[2]}/${valueArray[0]}`;
        this.$emit('input', this.hiddenInputValue);
      }
      this.isSelectOpened = false;
    },
    closePicker(eve) {
      if (
        !eve.target.matches('.hs-datepicker, .hs-datepicker *') ||
        (eve.target.classList.contains('hs-datepicker__value') &&
          eve.target != this.$refs.hsDatepicker)
      ) {
        this.isSelectOpened = false;
      }
    },
  },
  mounted() {
    this.setChosenValue();
    document.addEventListener('click', this.closePicker);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePicker);
  },
};
</script>

<style scoped lang="scss">
.hs-datepicker {
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  &__value,
  &__value:focus {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 18px;
    overflow: hidden;
    color: $darkGray;
    font-weight: 700;
    font-size: 14px;
    line-height: 48px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    background-color: $white;
    background-image: url('./icons/calendar-icon.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 18px) 50%;
    background-size: 18px auto;
    border: 1px solid $lightGray;
    border-radius: 4px;
    outline: 0;
  }
  &__value--placeholder {
    color: #a6a6a6;
    font-weight: normal;
  }
  &__errors-cont {
    display: block;
    width: 100%;
    color: $lighterRed;
    font-size: 12px;
  }
  &__calendar {
    position: fixed;
    top: 50%;
    right: 50%;
    z-index: -1;
    display: none;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 0 1000px rgba(58, 58, 58, 0.2);
    transform: translate(50%, -50%);

    @include mf($gridMDBreakpoint) {
      position: absolute;
      top: 100%;
      right: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      transform: translate(0, 0);
    }
  }
  &.is-active &__calendar {
    z-index: 10;
    display: block;
    width: 450px;
    max-width: 90vw;
    overflow: visible;
  }
  &__action-btns-cont {
    display: none;
    justify-content: space-between;
    padding-top: 30px;
  }
  &.is-active &__action-btns-cont {
    display: flex;
  }
  &__cancel-btn {
    background-image: url('./icons/cancel-icon.svg');
  }
  &__apply-btn {
    background-image: url('./icons/apply-icon.svg');
  }
  &__cancel-btn,
  &__apply-btn {
    padding-left: 27px;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: contain;
    &:hover {
      opacity: 0.45;
    }
  }
}
</style>
