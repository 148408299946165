<template>
  <ul class="navigation-menu-list">
    <li
      class="navigation-menu-list__list-item"
      :class="getListItemClasses(item)"
      v-for="item in filteredMenu"
      :key="item.name"
      v-on-clickaway="hideAll"
    >
      <template v-if="!item.items">
        <router-link
          v-if="item.to"
          :to="item.to($route)"
          class="navigation-menu-list__link"
          :class="{'navigation-menu-list__link--always-active': item.alwaysActive}"
          >{{ getItemName(item) }}</router-link
        >
        <a v-else :href="item.href" class="navigation-menu-list__link">
          {{ getItemName(item) }}
        </a>
      </template>

      <template v-else>
        <button
          class="navigation-menu-list__link navigation-menu-list__link--nested"
          :class="{
            'navigation-menu-list__link--active': isOpen[item.name],
          }"
          @click="toggle(item.name)"
          :ref="`${item.name}Button`"
        >
          <div
            v-if="item.iconClass"
            class="navigation-menu-list__icon"
            :class="`navigation-menu-list__icon--${item.iconClass}`"
          ></div>
          {{ getItemName(item) }}
        </button>
        <ul
          :ref="`${item.name}List`"
          class="navigation-menu-list__list--child"
          :class="{
            'navigation-menu-list__list--hidden': !isOpen[item.name],
          }"
        >
          <li
            class="navigation-menu-list__list-item navigation-menu-list__list-item--nested"
            v-for="childItem in getFilteredItems(item)"
            :key="childItem.name"
            :class="{
              'navigation-menu-list__list-item--blue': childItem.blue,
            }"
          >
            <a :href="childItem.href">{{ childItem.name }}</a>
          </li>
        </ul>

        <template v-if="alwaysShowDetachedItems">
          <div v-for="childItem in getDetachedItems(item)" :key="childItem.name">
            <div
              class="`navigation-menu-list__list-item navigation-menu-list__list-item--nested navigation-menu-list__list-item--detached-nested`"
              :class="{
                'navigation-menu-list__list-item--blue': childItem.blue,
              }"
            >
              <a :href="childItem.href">{{ childItem.name }}</a>
            </div>
          </div>
        </template>
      </template>
    </li>
  </ul>
</template>
<script>
import menu, {USER_NAME} from '@/constants/menu';
import {mixin as clickaway} from 'vue-clickaway';

const BASE_CSS_CLASS = 'navigation-menu-list';

export default {
  props: {
    alwaysShowDetachedItems: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [clickaway],
  data() {
    const nestedMenus = menu.filter(({items}) => items).map(({name}) => [name, false]);

    const isOpen = Object.fromEntries(nestedMenus);

    return {
      isOpen,
    };
  },

  methods: {
    getItemName(item) {
      let {name} = item;
      if (name === USER_NAME) {
        name = this.userName;
      }

      return name;
    },
    shouldShowItem(item) {
      let shouldShow = true;

      if (item.name === USER_NAME) {
        shouldShow = this.userName?.length > 0;
        // shouldShow = false;
      }

      return shouldShow;
    },
    getFilteredItems(item) {
      let {items} = item;
      if (this.alwaysShowDetachedItems) {
        items = items.filter((item) => !item.detached);
      }

      return items;
    },
    getListItemClasses(item) {
      const classesSuffixes = [];

      if (!!item.items) {
        const {isOpen} = this;
        classesSuffixes.push('arrow');

        if (!isOpen[item.name]) {
          classesSuffixes.push('arrow-reversed');
        }
      }

      if (this.hasDetachedMenuItems(item)) {
        classesSuffixes.push('detached');
      }

      return classesSuffixes.map((suffix) => `${BASE_CSS_CLASS}__list-item--${suffix}`);
    },
    getDetachedItems(item) {
      return item.items.filter((item) => item.detached);
    },
    hasDetachedMenuItems(item) {
      const {alwaysShowDetachedItems} = this;
      return alwaysShowDetachedItems && item?.items?.some((item) => item.detached);
    },
    animateListElHeight(itemName) {
      const {isOpen} = this;
      const listEl = this.$refs[`${itemName}List`][0];

      if (isOpen[itemName]) {
        Object.assign(listEl.style, {
          visibility: 'none',
          height: 'auto',
        });

        const {offsetHeight} = listEl;

        Object.assign(listEl.style, {
          visibility: null,
          height: null,
        });

        setTimeout(() => {
          Object.assign(listEl.style, {
            height: `${offsetHeight}px`,
          });
        });
      } else {
        Object.assign(listEl.style, {
          height: null,
        });
      }
    },
    setFixedWidthForButtonEl(itemName) {
      const el = this.$refs[`${itemName}Button`][0];

      const {offsetWidth, style} = el;

      if (!style.width || offsetWidth > parseInt(style.width)) {
        Object.assign(el.style, {
          width: `${offsetWidth}px`,
        });
      }
    },
    toggle(itemName) {
      const {isOpen} = this;
      for (const name of Object.keys(isOpen)) {
        if (name !== itemName) {
          isOpen[name] = false;
        } else {
          isOpen[name] = !isOpen[name];
        }

        this.setFixedWidthForButtonEl(name);
        this.animateListElHeight(name);
      }
    },
    hideAll(e) {
      const targetClasses = Array.from(e.target.classList);
      if (
        !targetClasses.some(
          (className) =>
            className.includes(BASE_CSS_CLASS) &&
            !className.includes(`${BASE_CSS_CLASS}__list-item`)
        )
      ) {
        const {isOpen} = this;

        for (const key of Object.keys(isOpen)) {
          isOpen[key] = false;
        }
      }
    },
  },

  computed: {
    menu: () => menu,
    filteredMenu() {
      return this.menu.filter((item) => this.shouldShowItem(item));
    },
    userName() {
      return this.$store.state.user.customerDetails?.initials;
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-menu-list {
  display: flex;
  align-items: center;
  margin-right: -26px;
  color: $darkGray;
  list-style-type: none;

  &__list {
    &--child {
      position: absolute;
      right: 24px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 180px;
      margin-top: -2px;
      padding: 10px;
      list-style-type: none;
      background-color: $white;
      border-top: 2px solid $primaryRed;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      transform-origin: 100% 0%;
      transition: all 0.2s ease 0s;
    }

    &--hidden {
      transform: scale(0);
    }
  }

  &__list-item {
    &--arrow {
      position: relative;
      &::before {
        position: absolute;
        right: 27px;
        // top: 50%;
        bottom: 8px;
        display: inline-block;
        width: 10px;
        height: 8px;
        background: url(~@/assets/images/nav-back-larger.svg) no-repeat 0 0/10px 8px;
        transform: rotate(-180deg);
        transition: all 0.2s ease 0s;
        content: '';
        pointer-events: none;
      }
    }

    &--arrow-reversed {
      &::before {
        transform: rotate(0deg);
      }
    }

    &--nested {
      font-size: 0.75rem;
      line-height: 25px;

      &:hover {
        color: $black;
        font-weight: bold;
        letter-spacing: -0.2px;
      }
    }

    &--blue {
      color: $blue;

      &:hover {
        color: $blue;
      }
    }
  }

  &__link {
    display: inline-block;
    margin: 10px 23px 0;
    font-size: 15px;
    border-bottom: 2px solid transparent;

    &.router-link-exact-active,
    &--always-active {
      font-weight: bold;
      border-bottom: 2px solid $primaryRed;
    }

    &--nested {
      padding-right: 19px;
    }

    &--active {
      font-weight: bold;
    }

    &:hover {
      border-bottom: 2px solid $primaryRed;
    }
  }

  &__icon {
    display: none;
    margin-right: 5px;
    vertical-align: bottom;
    pointer-events: none;

    &--user-icon {
      width: 25px;
      height: 25px;
      background: url(~@/assets/images/nav-user.svg) no-repeat 0 0/26px 26px;
    }
  }
}
</style>
