import camelcase from 'camelcase';

function toConstCase(string) {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toUpperCase();
}

export default function generateGuardedAsyncActions(unguardedActions) {
  const state = {};
  const mutations = {};
  const actions = {};

  for (const [actionName, action] of Object.entries(unguardedActions)) {
    // TODO: use toConstCase(actionName) instead of just actionName
    // if store will ever be rewritten
    // with camelCase action names
    const setInProgressMutationName = `SET_${actionName}_IN_PROGRESS`;
    const loadingPropName = `${camelcase(actionName)}InProgress`;

    state[loadingPropName] = false;

    mutations[setInProgressMutationName] = (state, inProgress) =>
      (state[loadingPropName] = inProgress);

    let pendingPromises = [];
    actions[actionName] = async (context, payload) => {
      return new Promise((resolve, reject) => {
        pendingPromises.push({resolve, reject});

        (async () => {
          const {commit, state} = context;
          if (!state[loadingPropName]) {
            commit(setInProgressMutationName, true);

            try {
              const result = await action(context, payload);
              for (const {resolve} of pendingPromises) {
                resolve(result);
              }
            } catch (e) {
              for (const {reject} of pendingPromises) {
                reject(e);
              }
            } finally {
              commit(setInProgressMutationName, false);
              pendingPromises.length = 0;
            }
          }
        })();
      });
    };
  }

  return {
    state,
    mutations,
    actions,
  };
}
