import {getRequestBody} from '@/utils/request';
import {API} from '@/constants/api';

const convert = async (store, code) => {
  const payload = getRequestBody({
    requestName: 'getCEProductsRequest',
    requestParams: {
      productCodes: {
        productCode: [code],
      },
      partnerCode: 'HS',
      state: 'OH',
    },
  });

  let res = await store.rootState.actionsDecorator({
    store,
    path: API.DEVICE.GET_TYPES,
    method: 'post',
    payload,
  });

  const {data} = res;

  return data?.response?.getCEProductsResponse?.productDetails;
};

const filterCEContracts = (plans, store) => {
  return plans?.map(async (planItem) => {
    const planCode = planItem?.productOfferCode;
    const ce = await convert(store, planCode);
    if (!!ce) {
      if (ce.some((c) => c.subCategoryCode === 'LS')) {
        planItem.type = 'premier';
      } else {
        planItem.type = 'essential';
      }
    }
    return !!ce ? planItem : null;
  });
};

export default filterCEContracts;
