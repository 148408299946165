<template>
  <!-- <strong class="logo">Homeserve</strong> -->
  <div class="logo">
    <LogoHouse class="logo__house" />
    <LogoHomeserve class="logo__homeserve" />
  </div>
</template>
<script>
import LogoHouse from '@/components/shared/LogoHouse';
import LogoHomeserve from '@/components/shared/LogoHomeserve';

export default {
  components: {
    LogoHouse,
    LogoHomeserve,
  },
};
</script>
<style lang="scss" scoped>
.logo {
  position: relative;
  height: 18px;

  @include mf($gridMDBreakpoint) {
    width: 168px;
    height: 71px;
  }

  &__house {
    position: absolute;
    width: 22px;
    height: 22px;

    @include mf($gridMDBreakpoint) {
      left: 37px;
      width: 38px;
      height: 38px;
    }
  }

  &__homeserve {
    position: absolute;
    left: 24px;
    width: 98px;

    @include mf($gridMDBreakpoint) {
      top: 36px;
      left: 0;
      width: 168px;
    }
  }
}
</style>
