const amountAvailableText = (claim) => {
  const buyoutAmountText = claim.claimDetails?.buyoutAmount;
  const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';

  return `
    We will pay you $${amount} based on the fair market value of your device at the time the Service Call was made, up to the benefit limit amount minus the Service Call fee. Once you accept this buyout option, payment processing and delivery will take up to 30 days.
    <b>Note: Your device will be automatically removed from your device list.
    Go to Service History to check on your Service Call progress.</b>
  `;
};

const oopText = (claim) => {
  let amount = 'a buyout amount';
  let buyoutAmount = claim?.claimDetails?.buyoutAmount;
  if (buyoutAmount) {
    amount = parseInt(buyoutAmount, 10);
  }
  return `We will pay you $${amount} based on the fair market value of your device at the time the Service Call was made up to the benefit limit amount. Once you accept this buyout option, payment processing and delivery will take up to 30 days.
          <b>Note: Your device will be automatically removed from your device list.
          Go to Service History to check on your Service Call progress.</b>`;
};

const YOU_WILL_RECEIVE_THIS_AMOUNT_TEXT =
  'You will receive this amount up to the benefit amount (minus the Service Call fee, if applicable.)';

const getFairMarketValueText = (claim) => {
  const buyoutAmountText = claim.claimDetails?.buyoutAmount;
  const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : null;
  if (amount === null) {
    return '';
  }

  return `The fair market value of your device is $${amount}`;
};

// returns list of reusable statuses
export const getSharedStatuses = (overrides = {}) => {
  // TODO: To clarify these statuses
  const beyondEconomicalRepairStatuses = {
    beyondEconomicalRepairIdentified: {
      code: 'SC0056',
      text: 'BER identified.',
    },
    claimEstimationGenerated: {
      code: 'SC0044',
      virtualStatus: 'Service Call in progress',
      heading: 'Your Service Call is in progress',
      text: 'We are in the process of resolving your request and your Service Call is in progress.',
    },
    claimEstimationApproved: {
      code: 'SC0045',
      virtualStatus: 'Service Call in progress',
      heading: 'Your Service Call is in progress',
      text: 'We are in the process of resolving your request and your Service Call is in progress.',
    },
  };

  // TODO: To clarify these statuses
  const devicePartsStatuses = {
    devicePartsPending: {
      code: 'SC0020',
      virtualStatus: 'Service Call in progress',
      heading: 'Your Service Call is in progress',
      text: 'We are in the process of resolving your request and your Service Call is in progress.',
    },
    devicePartsReceived: {
      code: 'SC0021',
      virtualStatus: 'Service Call in progress',
      heading: 'Your Service Call is in progress',
      text: 'We are in the process of resolving your request and your Service Call is in progress.',
    },
  };

  const repairCompletionStatuses = {
    repairCompleted: {
      code: 'SC0002',
      text: 'Repair has successfully been completed.',
    },
    repairCancelled: {
      code: 'SC0033',
      text: (_, {phones}) => `
        If you have any questions, please call HomeServe at ${phones.contactCenter}.
      `,
    },
  };

  const repairRefusalStatuses = {
    // repair refusal statuses
    deviceNotEligibleForRepair: {
      code: 'SC0079',
      text: (_, {phones}) => `
        Please check your Service Agreement for more information.
        If you have any questions, please call HomeServe at ${phones.contactCenter}.
      `,
    },
    deviceNotRepairable: {
      code: 'SC0080',
      virtualStatus: 'Device Not Repairable. Buyout Ready for Decision.',
      heading: `We're not going to repair your device. A buyout option is recommended.`,
      text: (claim) => {
        const buyoutAmountText = claim.claimDetails?.buyoutAmount;
        const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : null;

        const amountText = amount
          ? `
          We will pay you $${amount} based on the fair market value of your device at the time the Service Call was made up to the benefit limit amount. Once you accept this buyout option, payment processing and delivery will take up to 30 days.
          <b>Note: Your device will be automatically removed from your device list.
          Go to Service History to check on your Service Call progress.</b>
        `
          : '';
        return `
          ${amountText}
        `;
      },
    },
    // TODO: clarify this status
    noFaultFound: {
      code: 'SC0082',
      text: 'No fault found.',
    },
  };

  const buyoutStatuses = {
    // TODO: To clarify this status
    convertedToBuyout: {
      code: 'SC0300',
      text: 'Your Service Call has been converted to buyout.',
    },

    amountAvailable: {
      code: 'SC0100',
      virtualStatus: 'Ready for Decision',
      heading: 'Please review the Buyout amount available',
      text: amountAvailableText,
    },
  };

  const oopStatuses = {
    oopDeclined: {
      // TODO: This status might be changed on ESB side.
      code: ['HS001', 'HS002'],
      virtualStatus: 'OOP Declined. Buyout Ready For Decision.',
      heading: `We're not going to repair your device. A buyout option is recommended.`,
      text: oopText,
    },
  };

  const paymentStatuses = {
    // TODO: To clarify this status
    paymentReceived: {
      code: 'SC0035',
      virtualStatus: 'Service Call in progress',
      heading: 'Your Service Call is in progress',
      text: 'We are in the process of resolving your request and your Service Call is in progress.',
    },
    paymentPending: {
      code: 'SC0046',
      text: (_, {phones}) => `
        We have determined that the repair will cost more than what your plan covers.
        An update was sent to you via email/SMS.
        Please check your Service Agreement for more information.
        If you have any additional questions, feel free to call HomeServe at ${phones.contactCenter}.
      `,
    },
  };

  const serviceCompletionStatuses = {
    serviceCompleted: {
      code: 'SC0004',
      text: 'Service has successfully been completed.',
    },
    serviceCancelled: {
      code: ['SC0003', 'CAN'],
      virtualStatus: 'Service cancelled',
      text: (_, {phones}) => `
        If you have any questions, please call HomeServe at ${phones.contactCenter}.
      `,
    },
  };

  const sharedStatuses = {
    ...beyondEconomicalRepairStatuses,
    ...devicePartsStatuses,
    ...repairCompletionStatuses,
    ...repairRefusalStatuses,
    ...buyoutStatuses,
    ...oopStatuses,
    ...paymentStatuses,
    ...serviceCompletionStatuses,
  };

  for (const [statusName, statusOverrides] of Object.entries(overrides)) {
    Object.assign(sharedStatuses[statusName], statusOverrides);
  }

  return sharedStatuses;
};

const SHARED_STATUSES = getSharedStatuses();

export const getStatusCodeFor = (statusName) => {
  let status = SHARED_STATUSES[statusName]?.code;
  if (Array.isArray(status)) {
    status = status.find((statusCode) => statusCode.startsWith('SC'));
  }

  return status;
};

// returns {[status.code]: {name, ...status}} index
export const getStatusCodesResolver = (statuses, resolverCallback) => {
  const entries = [];

  const addStatus = (code, statusData) => {
    code = code.toLowerCase();
    let entry = entries.find(([entryCode]) => entryCode === code);

    if (!entry) {
      entry = [code, []];
      entries.push(entry);
    }

    entry[1].push(statusData);
  };

  for (const [name, status] of Object.entries(statuses)) {
    if (Array.isArray(status.code)) {
      for (const code of status.code) {
        addStatus(code, {name, ...status});
      }
    } else if (status.code) {
      addStatus(status.code, {name, ...status});
    }
  }

  const statusCodesIndex = Object.fromEntries(entries);

  return (jobInfo, code) => {
    code = code.toLowerCase();

    const statuses = statusCodesIndex[code];
    let status = statuses?.[0];

    if (resolverCallback && statuses?.length > 1) {
      const resolvedStatus = resolverCallback(jobInfo, statuses);
      if (resolvedStatus) {
        status = resolvedStatus;
      }
    }
    if (statuses) {
    } else {
      const jobStatuses = statusCodesIndex[jobInfo.serviceJobStatus.toLowerCase()];
      status = jobStatuses?.[0];
    }

    return status;
  };
};
