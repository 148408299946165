const state = {
  showModal: false,
  showAuthModal: false,
  title: '',
  text: [],
  callCenter: false,
  cta: [],
};

const getters = {};

const actions = {};

const mutations = {
  ['SET_ERROR_MODAL_DATA']: (state, data) => {
    state.showModal = true;
    state.title = data.title;
    state.text = data.text;
    state.callCenter = data.callCenter;
    state.cta = data.cta;
  },
  ['SET_AUTH_ERROR_MODAL']: (state, value) => {
    state.showAuthModal = value;
  },
  ['CLEAR_ERROR_MODAL_DATA']: (state) => {
    state.showModal = false;
    state.showAuthModal = false;
    state.title = '';
    state.text = [];
    state.callCenter = false;
    state.cta = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
