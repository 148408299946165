<template>
  <header>
    <div class="header__top">
      <div class="content">
        <div class="header__line">
          <p class="header__line-item header__line-item--phone">
            <a :href="phones.contactCenterTel">{{ phones.contactCenterFormatted }}</a>
          </p>
          <!-- <p class="header__line-item header__line-item--location">Enter ZIP</p>
          <AuthenticationButton class="header__line-item header__line-item--user" />
          <p class="header__line-item header__line-item--no-icon">Blog</p>
          <p class="header__line-item header__line-item--mobile">The HomeServe App</p>
          <p class="header__cart" :data-cart-items="cartItems"></p> -->
        </div>
      </div>
    </div>
    <div class="content">
      <div class="header__columns">
        <button class="header__hamburger-btn" @click="handleMobileNav">☰</button>
        <Logo />
        <div class="header__nav-cont">
          <!-- <hs-button size="xs" class="header__service-btn">Request Service</hs-button> -->
          <Navigation />
        </div>
        <div class="header__mobile-actions-cont">
          <!-- <button class="header__mobile-action-btn header__mobile-action-btn--location"></button> -->
          <button class="header__mobile-action-btn header__mobile-action-btn--phone"></button>
          <!-- <p class="header__cart" :data-cart-items="cartItems"></p> -->
        </div>
      </div>
    </div>
    <MobileNavigation :visible="showMobileNav" @hide="showMobileNav = false" />
  </header>
</template>

<script>
import Logo from '@/components/shared/Logo';
import phones from '@/constants/phones';
import Navigation from '@/components/header/Navigation';
import MobileNavigation from '@/components/header/MobileNavigation';

export default {
  components: {
    Logo,
    Navigation,
    MobileNavigation,
  },
  data() {
    return {
      cartItems: 7,
      showMobileNav: false,
    };
  },
  methods: {
    handleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
  },
  computed: {
    phones: () => phones,
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 23px 0 12px;

  @include mf($gridMDBreakpoint) {
    padding: 0;
    border-bottom: 4px solid $primaryRed;
  }
}
.header {
  &__line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    line-height: 18px;
  }
  &__line-item {
    position: relative;
    display: inline-block;
    padding-left: 15px;
    &:not(:last-child) {
      margin-right: 15px;
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 15px;
      background-repeat: no-repeat;
      background-position: 0 50%;
      content: '';
    }
    &--phone::before {
      top: -5px;
      left: -5px;
      background-image: url('~@/assets/images/header/phone.svg');
      background-size: 11px auto;
      transform: rotate(270deg);
    }
    &--location::before {
      background-image: url('~@/assets/images/header/location.svg');
      background-size: 8px auto;
    }
    &--user::before {
      background-image: url('~@/assets/images/header/user.svg');
      background-size: 9px auto;
    }
    &--mobile::before {
      background-image: url('~@/assets/images/header/mobile.svg');
      background-size: 8px auto;
    }
    &--no-icon {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }
  &__cart {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 1;
    background-image: url('~@/assets/images/header/cart.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    @include mf($gridMDBreakpoint) {
      width: 17px;
      height: 20px;
      margin-left: 15px;
      font-size: 10px;
    }
    &::before {
      position: absolute;
      top: -2px;
      left: 50%;
      background-color: $white;
      transform: translateX(-30%);
      content: attr(data-cart-items);

      @include mf($gridMDBreakpoint) {
        top: 0;
        background-color: $lighterGray;
      }
    }
  }
  &__top {
    display: none;

    @include mf($gridMDBreakpoint) {
      display: block;
      padding: 1.5px 0;
      background: $lighterGray;
    }
  }
  &__columns {
    display: flex;
    align-items: center;

    @include mf($gridMDBreakpoint) {
      align-items: flex-end;
      justify-content: space-between;
      padding: 12px 0;
    }
  }
  &__nav-cont {
    display: none;

    @include mf($gridMDBreakpoint) {
      display: block;
    }
  }
  &__service-btn {
    display: flex;
    justify-content: flex-end;
  }
  &__hamburger-btn {
    padding-right: 17px;
    color: $darkGray;
    font-size: 36px;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__mobile-actions-cont {
    display: flex;
    margin-left: auto;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__mobile-action-btn {
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 25px;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
    &--phone {
      background-image: url('~@/assets/images/header/phone_in_circle.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
    }
    &--location {
      background-image: url('~@/assets/images/header/location.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 20px auto;
    }
  }
}
</style>
