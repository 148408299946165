import HSButton from './components/HSButton/HSButton';
import HSCheckbox from './components/HSCheckbox/HSCheckbox';
import HSRadio from './components/HSRadio/HSRadio';
import HSSwitch from './components/HSSwitch/HSSwitch';
import HSInput from './components/HSInput/HSInput';
import HSTextarea from './components/HSTextarea/HSTextarea';
import HSSelect from './components/HSSelect/HSSelect';
import HSHeading from './components/HSHeading/HSHeading';
import HSSearch from './components/HSSearch/HSSearch';
import HSLabel from './components/HSLabel/HSLabel';
import HSPopup from './components/HSPopup/HSPopup';
import HSDatepicker from './components/HSDatepicker/HSDatepicker';
import HSMonthCalendar from './components/HSDatepicker/HSMonthCalendar';
import HSMainContent from './components/HSMainContent/HSMainContent';

const HSDSPlugin = {};

HSDSPlugin.install = (Vue) => {
  Vue.component('hs-button', HSButton);
  Vue.component('hs-checkbox', HSCheckbox);
  Vue.component('hs-radio', HSRadio);
  Vue.component('hs-switch', HSSwitch);
  Vue.component('hs-input', HSInput);
  Vue.component('hs-textarea', HSTextarea);
  Vue.component('hs-select', HSSelect);
  Vue.component('hs-heading', HSHeading);
  Vue.component('hs-search', HSSearch);
  Vue.component('hs-label', HSLabel);
  Vue.component('hs-popup', HSPopup);
  Vue.component('hs-datepicker', HSDatepicker);
  Vue.component('hs-month-calendar', HSMonthCalendar);
  Vue.component('hs-main', HSMainContent);
};

export default HSDSPlugin;
export {
  HSButton,
  HSCheckbox,
  HSRadio,
  HSSwitch,
  HSInput,
  HSTextarea,
  HSSelect,
  HSHeading,
  HSSearch,
  HSLabel,
  HSPopup,
  HSDatepicker,
  HSMonthCalendar,
  HSMainContent,
};
