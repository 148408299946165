import {API} from '@/constants/api';
import isPlanActive from '@/constants/activePlanCodes';
import filterCEContracts from '@/utils/ceContracts';
import {getRequestBody} from '@/utils/request';
import generateGuardedAsyncActions from '@/store/generateGuardedAsyncActions';

const guardedActions = generateGuardedAsyncActions({
  async GET_TERRITORY(context, zip) {
    const payload = getRequestBody({
      requestName: 'territoryLookupRequest',
      requestParams: {
        zipCode: zip,
      },
    });

    const {data} = await context.rootState.actionsDecorator({
      store: context,
      payload,
      path: API.ADDRESS.GET_TERRITORY,
      method: 'post',
    });

    return data.response?.territoryLookupResponse;
  },
});

const state = {
  ...guardedActions.state,
  thirtyDaysExpired: null,
  customerDetails: null,
  contractDetails: null,
  customerProducts: null,
  cePlanCodes: [],
  premierPlanCodes: [],
  supportSmartphoneClaim: null,
  planCode: null,
  onlineClaimEligible: null,
  showSelectContractModal: false,
  contracts: [],
};

const getters = {
  planCode: ({planCode}) => planCode,

  partnerCode: (contracts) => {
    // const contract = contracts[0];
    const contract = contracts.contractDetails;

    if (!contract) {
      return null;
    }
    const {partnerCode} = contract;
    console.log('partnerCode-' + partnerCode);
    return partnerCode;
  },

  fullName: ({customerDetails = {}}) => {
    // const {NameDetail} = customerDetails;
    return `${customerDetails?.FirstName} ${customerDetails?.LastName}`.trim();
    // return `${customerDetails.irstName} ${customerDetails.surname}`.trim();
  },

  mobileNumber: ({customerDetails}) => {
    //const contactDetail = customerDetails?.ContactDetail;
    // const contactDetail = customerDetails;   old value
    const contactDetail = customerDetails;

    let phone;

    const phoneFields = ['mobilephone', 'homePhone', 'workPhone'];

    for (const fieldName of phoneFields) {
      phone = contactDetail?.[fieldName];
      if (phone) {
        break;
      }
    }

    return phone;
  },

  email: ({customerDetails}) => {
    return customerDetails?.email || '';
  },

  productLinesClaims: ({customerProducts}) => {
    const claimClass = customerProducts?.product?.claimClasses?.claimclass || [];
    const filteredDetails = claimClass.filter((details) => details.featurCodeDetail?.featureCode);

    const productLinesClaimsIndexEntries = filteredDetails.map((details) => {
      const {featureCode} = details.featurCodeDetail;
      return featureCode.map((feature) => [feature.productLine, details]);
    });

    const productLinesClaims = Object.fromEntries([].concat(...productLinesClaimsIndexEntries));

    return productLinesClaims;
  },
};

const actions = {
  ...guardedActions.actions,
  ['DECRYPT_PARAMS']: (store, params) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.DECRYPT_PARAMS,
          method: 'post',
          payload: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['ENCRYPT_PARAMS']: (store, params) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.ENCRYPT_PARAMS,
          method: 'post',
          payload: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CUSTOMER_REF']: async (store, {customerRef}) => {
    const payload = getRequestBody({
      requestName: 'searchByCustomerRefRequest',
      requestParams: {
        customerRef: customerRef,
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CUSTOMER_REF,
          method: 'post',
          payload,
        })
        .then(async (res) => {
          const details = res?.data?.response?.searchByCustomerRefResponse;
          store.commit('SET_GET_CUSTOMER_REF', details);
          resolve(details);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  ['GET_CUSTOMER_DETAILS']: async (store, data) => {
    const payload = getRequestBody({
      requestName: 'getAccountSummaryRequest',
      requestParams: {
        contactId: data.contactId,
        accountAddressId: '',
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CUSTOMER_DETAILS,
          method: 'post',
          payload,
        })
        .then(async (res) => {
          const details = res?.data?.response?.getAccountSummaryResponse;
          let currentAddress = {};
          if (details.errorDetails) {
            return 'Error';
          }
          details?.contactCollection[0]?.addressCollection.map((address) => {
            if (address.customerRef == data.customerRef) {
              currentAddress = {
                ...address,
                contactId: details?.contactCollection[0]?.contactId,
                email: details?.contactCollection[0]?.email,
                FirstName: details?.contactCollection[0]?.firstName,
                homephone: details?.contactCollection[0]?.homephone,
                LastName: details?.contactCollection[0]?.lastName,
                mobilephone: details?.contactCollection[0]?.mobilephone,
              };
            }
            return address;
          });

          store.commit('SET_CUSTOMER_DETAILS', currentAddress);

          let contracts = [];

          if (Array.isArray(details?.contactCollection)) {
            let addressCollection = {};
            details.contactCollection[0]?.addressCollection.map((address) => {
              if (
                address.customerRef == data.customerRef &&
                (address.addressType == 'Both' || address.addressType == 'Service')
              ) {
                if (address.contractCollection.length > 0) {
                  addressCollection = address;
                }
              }
              return address;
            });

            contracts = addressCollection.contractCollection;
          } else if (details?.contactCollection?.addressCollection) {
            contracts = [details.contactCollection?.addressCollection];
          }
          console.log(contracts, 'contracts');
          const activeContracts = contracts.filter((c) => {
            return isPlanActive(c?.contractStatusCode);
          });

          // const activeContracts = contracts.filter((c) =>
          //   isPlanActive(c?.addressCollection[0].contractCollection[0].contractStatusCode)
          // );
          // const activeContracts = contracts.flatMap((c) =>
          //   c?.addressCollection.flatMap((item) => {
          //     // const activePlan = item.contractCollection.filter(
          //     //   (plan) => plan.contractPublicRef === data.contractRef
          //     // );
          //     const activePlan =
          //       item.contractCollection.filter(
          //         (plan) => plan.contractPublicRef === data.contractRef
          //       ).length > 0
          //         ? item.contractCollection.filter(
          //             (plan) => plan.contractPublicRef === data.contractRef
          //           )
          //         : [item.contractCollection[0]];
          //     if (activePlan.length > 0) {
          //       isPlanActive(activePlan[0]?.contractStatusName);
          //       return activePlan;
          //     } else {
          //       return [];
          //     }
          //   })
          // );

          Promise.all(filterCEContracts(activeContracts, store))
            .then((ceContracts) => {
              const filteredPlans = ceContracts.filter((c) => !!c);
              console.log(filteredPlans, 'filteredPlans');
              store.commit('SET_CUSTOMER_CONTRACTS', filteredPlans);
              store.commit(
                'SET_PREMIER_PLAN_CODES',
                filteredPlans.filter((p) => p.type === 'premier').map((p) => p.productOfferCode)
              );
              store.commit(
                'SET_CE_PLAN_CODES',
                filteredPlans.map((p) => p.productOfferCode)
              );
            })
            .finally(() => {
              resolve(details);
            });
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CONTRACT_DETAILS']: async (store, data) => {
    const payload = getRequestBody({
      requestName: 'getContractRequest',
      requestParams: {
        contractId: data.contractId,
        resultSet: 'EXTENDED',
        returnContracts: true,
        returnPaymentDetails: true,
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CONTRACT_DETAILS,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit('SET_CONTRACT_DETAILS', res?.data?.response?.getContractResponse);

          const optionDetail = store.state;
          if (optionDetail.versionFromDate) {
            const coverFromDateArr = optionDetail?.versionFromDate?.split('-');
            if (coverFromDateArr[2]?.length == 2) coverFromDateArr[2] = '20' + coverFromDateArr[2];
            const nowDate = new Date();
            const coverFromDate = new Date(
              +coverFromDateArr[2],
              coverFromDateArr[0] - 1,
              +coverFromDateArr[1],
              0,
              0,
              0,
              0
            );
            console.log('coverFromDate');
            console.log(+nowDate < +coverFromDate);

            if (+nowDate < +coverFromDate) {
              store.commit('SET_THIRTY_DAYS', false);
            }
          }

          const contractPlanCode = store.state?.contractDetails?.productOfferCode;

          // if (contractPlanCode && !store.state?.premierPlanCodes?.includes(contractPlanCode)) {
          //   store.commit('SET_SMARTPHONE_SUPPORT', false);
          // }

          if (contractPlanCode) {
            store.commit('SET_PLAN_CODE', contractPlanCode);
          }
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_CONTRACT']: async (store, {scheduleRef}) => {
    const payload = getRequestBody({
      requestName: 'getContractDetailsRequest',
      requestParams: {
        ScheduleRef: scheduleRef,
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_CONTRACT_DETAILS,
          method: 'post',
          payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_PRODUCTS']: async (store, data) => {
    const payload = getRequestBody({
      requestName: 'getProductDetailsRequest',
      requestParams: {
        product: [{...data}],
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_PRODUCTS,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit(
            'SET_CUSTOMER_PRODUCTS',
            res?.data?.response?.getProductDetailsResponse?.products
          );
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_ONLINE_ELIGIBILITY']: async (store, data) => {
    const payload = getRequestBody({
      requestName: 'claimGetOnlineEligibilityRequest',
      requestParams: {...data},
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.USER.GET_ONLINE_ELIGIBILITY,
          method: 'post',
          payload,
        })
        .then((res) => {
          if (
            res?.data?.response?.claimGetOnlineEligibilityResponse?.onlineClaims
              ?.onlineClaimEligibility === true ||
            res?.data?.response?.claimGetOnlineEligibilityResponse?.onlineClaims
              ?.onlineClaimEligibility === 'true'
          ) {
            store.commit('SET_ONLINE_ELIGIBILITY', true);
          } else {
            store.commit('SET_ONLINE_ELIGIBILITY', false);
          }
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['SHOW_SELECT_CONTRACT_MODAL']: async ({commit}) => {
    commit('SET_CONTRACT_MODAL_VISIBILITY', true);
  },
  ['HIDE_SELECT_CONTRACT_MODAL']: async ({commit}) => {
    commit('SET_CONTRACT_MODAL_VISIBILITY', false);
  },
};

const mutations = {
  ...guardedActions.mutations,
  ['SET_THIRTY_DAYS']: (state, value) => {
    state.thirtyDaysExpired = value;
  },
  ['SET_GET_CUSTOMER_REF']: (state, data) => {
    console.log(state, data, 'insii');
    state.contactRef = data;
  },
  ['SET_CUSTOMER_DETAILS']: (state, data) => {
    state.customerDetails = data;
  },
  ['SET_CUSTOMER_CONTRACTS']: (state, data) => {
    state.contracts = data;
  },
  ['SET_CONTRACT_DETAILS']: (state, data) => {
    state.contractDetails = data;
  },
  ['SET_CE_PLAN_CODES']: (state, data) => {
    state.cePlanCodes = data;
  },
  ['SET_PREMIER_PLAN_CODES']: (state, data) => {
    state.premierPlanCodes = data;
  },
  ['SET_CUSTOMER_PRODUCTS']: (state, data) => {
    state.customerProducts = data;
  },
  ['SET_SMARTPHONE_SUPPORT']: (state, value) => {
    state.supportSmartphoneClaim = value;
  },
  ['SET_PLAN_CODE']: (state, value) => {
    state.planCode = value;
  },
  ['SET_ONLINE_ELIGIBILITY']: (state, value) => {
    state.onlineClaimEligible = value;
  },
  ['SET_CONTRACT_MODAL_VISIBILITY']: (state, show) => {
    state.showSelectContractModal = show;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
