import Vue from 'vue';
import VueRouter from 'vue-router';
import {scBase} from '@/constants/web-redirects';
import store from '@/store/index';
import {allowClaims} from '@/constants/env';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    beforeEnter(from, to, next) {
      window.location.href = scBase + '/sc/login';
    },
  },
  {
    path: '/ds-demo',
    name: 'DSDemo',
    component: () => import(/* webpackChunkName: "ds-demo" */ '@/views/DSDemo.vue'),
  },
  {
    path: '/device',
    name: 'Device',
    component: () => import(/* webpackChunkName: "device" */ '@/views/Device.vue'),
    children: [
      {
        path: 'add',
        name: 'AddDevice',
        component: () => import(/* webpackChunkName: "add-device" */ '@/views/device/Add.vue'),
      },
      {
        path: 'my-devices',
        name: 'MyDevices',
        component: () =>
          import(/* webpackChunkName: "my-devices" */ '@/views/device/MyDevices.vue'),
      },
      {
        path: ':id',
        name: 'DeviceInfo',
        component: () => import(/* webpackChunkName: "device-info" */ '@/views/device/Info.vue'),
      },
    ],
  },
  {
    path: '/service-request-resolved',
    name: 'ClaimResolved',
    component: () => import(/* webpackChunkName: "claim-resolved" */ '@/views/claims/Resolved.vue'),
  },
  {
    path: '/service-request',
    name: 'Claim',
    component: () => import(/* webpackChunkName: "claim" */ '@/views/Claims.vue'),
    children: [
      {
        path: ':id/create',
        name: 'StartAClaim',
        component: () =>
          import(/* webpackChunkName: "create-claim" */ '@/views/claims/StartClaim.vue'),
      },
      {
        path: ':id/confirm/:type',
        name: 'ConfirmAClaim',
        component: () =>
          import(
            /* webpackChunkName: "confirm-claim" */ '@/components/service-request/Confirm.vue'
          ),
      },
      {
        path: ':id/create/:type',
        name: 'StartSpecificClaim',
        component: () =>
          import(
            /* webpackChunkName: "create-specific-claim" */ '@/views/claims/StartSpecificClaim.vue'
          ),
      },
    ],
  },
  {
    path: '/job',
    name: 'Job',
    component: () => import(/* webpackChunkName: "jobs" */ '@/views/Jobs.vue'),
    children: [
      {
        path: ':id',
        name: 'ShowJob',
        component: () => import(/* webpackChunkName: "show-job" */ '@/views/jobs/Show.vue'),
      },
      {
        path: ':id/edit',
        name: 'EditJob',
        component: () => import(/* webpackChunkName: "edit-job" */ '@/views/jobs/Edit.vue'),
      },
      {
        path: ':id/carry-in-repair-success',
        name: 'CarryInRepairSuccess',
        component: () =>
          import(
            /* webpackChunkName: "carry-in-success" */ '@/views/claims/CarryInSuccessPage.vue'
          ),
      },
      {
        path: ':id/depot-repair-success',
        name: 'DepotRepairSuccess',
        component: () =>
          import(
            /* webpackChunkName: "depot-success" */ '@/views/claims/DepotRepairSuccessPage.vue'
          ),
      },
      {
        path: ':id/reimbursable-repair-success',
        name: 'ReimbursementSuccess',
        component: () =>
          import(
            /* webpackChunkName: "reimbursement-success" */ '@/views/claims/ReimbursementSuccessPage.vue'
          ),
      },

      {
        path: ':id/replacement-success',
        name: 'ReplacementSuccess',
        component: () =>
          import(
            /* webpackChunkName: "replacement-success" */ '@/views/claims/ReplacementSuccessPage.vue'
          ),
      },

      {
        path: ':id/home-service-success',
        name: 'HomeServiceSuccess',
        component: () =>
          import(
            /* webpackChunkName: "home-service-success" */ '@/views/claims/HomeRepairServiceSuccessPage.vue'
          ),
      },
    ],
  },
  {
    path: '/service-history',
    name: 'ServiceHistory',
    component: () => import(/* webpackChunkName: "service-history" */ '@/views/ServiceHistory.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return savedPosition ? savedPosition : {x: 0, y: 0};
  },
});

router.beforeEach((from, to, next) => {
  const q = from.query;
  const {customerRef, contractRef} = q;
  if (!customerRef) {
    window.location.href = scBase + '/sc/login';
  }
  if (!contractRef && allowClaims) {
    console.warn('No contract specified!');
    store.dispatch('user/SHOW_SELECT_CONTRACT_MODAL');
  }
  next();
});

export default router;
