import {API} from '@/constants/api';
import {getRequestBody} from '@/utils/request';
import {allowClaims} from '@/constants/env';
import {compareStates, getStateCode} from '@/constants/us_states';

const state = {
  jobInfo: null,
  claimsList: null,
};

const getters = {
  jobInfo: (state, {getDropOffLocation, getAddress}, rootState) => {
    const result = state.jobInfo ?? {};

    if (!result.claimDetails) {
      result.claimDetails = {};
    }

    if (!result.location) {
      result.location = {};
    }

    result.deviceName = rootState.deviceInformation.equipmentName;
    result.dropOffLocation = getDropOffLocation(result);
    result.address = getAddress(result);

    return result;
  },

  getAddress: () => (claim) => {
    const address = {};

    const oneToOneFields = ['city', 'state', 'houseNo', 'flatNo', 'streetName'];
    for (const fieldName of oneToOneFields) {
      const fieldNameCapitalised = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
      address[fieldName] = claim[`customer${fieldNameCapitalised}`];
    }

    address.streetAddress = address.streetName;
    address.zipCode = claim.customerPostalCode;

    {
      const {houseNo, streetName, city, state} = address;

      const chunks = [`${houseNo} ${streetName}`];
      if (compareStates(city, state)) {
        chunks.push(getStateCode(state));
      } else {
        chunks.push(city, getStateCode(state));
      }

      address.fullAddress = chunks.join(', ');
    }

    return address;
  },

  getDropOffLocation: () => (claim) => {
    const shippingLocation = {};

    const claimDetails = claim?.claimDetails || {};
    const PREFIX = 'dropOffLocation';
    for (const [key, value] of Object.entries(claimDetails)) {
      if (key.startsWith(PREFIX)) {
        const keyWithoutPrefix = key.replace(PREFIX, '');
        const newKey = keyWithoutPrefix.charAt(0).toLowerCase() + keyWithoutPrefix.slice(1);

        shippingLocation[newKey] = value;
      }
    }

    {
      const {address, city, state} = shippingLocation;

      const chunks = [address];
      if (compareStates(city, state)) {
        chunks.push(getStateCode(state));
      } else {
        chunks.push(city, getStateCode(state));
      }

      shippingLocation.fullAddress = chunks.join(', ');
    }

    return shippingLocation;
  },

  getByMasterId: (state) => (masterId) => {
    return state?.claimsList?.find((claim) => claim.claimsMasterId === masterId);
  },

  getByDeviceId: (state) => (deviceId) => {
    const claimsForDevice = state?.claimsList?.filter(
      (claim) => claim.claimDetails?.inventoryEquipmentID === deviceId
    );
    if (claimsForDevice?.length > 1) {
      claimsForDevice.sort((a, b) => {
        const aDate = new Date(a?.claimDetails?.createDate);
        const bDate = new Date(b?.claimDetails?.createDate);
        return bDate - aDate;
      });
    }
    return claimsForDevice[0];
  },

  getByServiceJobId: (state) => (serviceJobId) => {
    return state?.claimsList?.find((claim) => claim.serviceJobID === serviceJobId);
  },
};

const actions = {
  ['GET_CLAIMS_LIST']: async (store, params) => {
    return new Promise((resolve, reject) => {
      // TODO: A workaround to fix missing claim details on a device page.
      if (!allowClaims) {
        store.commit('SET_CLAIMS_LIST', []);
        resolve([]);
        return;
      }

      const payload = getRequestBody({
        requestName: 'getServiceJobDetailsRequest',
        requestParams: {...params},
      });
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_CLAIMS_LIST,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit(
            'SET_CLAIMS_LIST',
            res?.data?.response?.getServiceJobDetailsResponse?.serviceJobStatusDetail
          );
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_INFO']: (store, {id}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.JOB.GET_INFO,
          method: 'get',
          payload: {
            params: {
              id,
            },
          },
        })
        .then((res) => {
          const jobInfo = res.data[id];
          if (jobInfo) {
            jobInfo.id = id;
          }
          store.commit('SET_JOB_INFO', jobInfo);
          resolve(jobInfo);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_BUYOUT_AMOUNT']: (store, payload = {}) => {
    const {serviceJobId} = payload;
    const getBuyoutAmountRequestPayload = getRequestBody({
      requestName: 'getBuyoutAmountRequest',
      requestParams: {
        serviceJobId,
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.CLAIMS.GET_BUYOUT_AMOUNT,
          method: 'post',
          payload: getBuyoutAmountRequestPayload,
        })
        .then((res) => {
          const {commit} = store;
          const buyoutAmount = res?.data?.response?.getBuyoutAmountResponse?.buyoutAmount;
          const errorCode =
            res.data?.response?.getBuyoutAmountResponse?.errorDetails?.errorDetail?.errorCode;
          if (!errorCode) {
            commit('SET_BUYOUT_AMOUNT', buyoutAmount);
          }
          resolve(buyoutAmount);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_HISTORY']: (store, {id}) => {
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.JOB.GET_HISTORY,
          method: 'get',
          payload: {
            params: {
              id,
            },
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  ['SET_JOB_INFO']: (state, data) => {
    state.jobInfo = data;
    // For Service Bench jobs this shows buyout.
    // For HS jobs this should be replaced with data from getBuyoutAmount.
    if (state.jobInfo.sourceSystem?.toUpperCase() !== 'SERVICEBENCH') {
      state.jobInfo.claimDetails.buyoutAmount = null;
    }
  },
  ['SET_BUYOUT_AMOUNT']: (state, amount) => {
    if (state.jobInfo && state.jobInfo.claimDetails) {
      state.jobInfo.claimDetails.buyoutAmount = amount;
    }
  },
  ['SET_CLAIMS_LIST']: (state, data) => {
    state.claimsList =
      data?.filter((i) =>
        ['SERVIFY', 'WEB', 'SERVICEBENCH'].includes(i.sourceSystem.toUpperCase())
      ) || [];
  },
  // TODO: Only for demo purposes
  ['SET_CLAIM_STATUS']: (state, status) => {
    state.jobInfo.status = status;
  },
  ['CLEAR_STORE']: (state) => {
    state.jobInfo = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
