import {scBase} from '@/constants/web-redirects';

export const USER_NAME = 'USER_NAME';

const menu = [
  {
    name: 'My Plans',
    path: 'my-plans',
  },
  {
    name: 'My Devices',
    to: ({query}) => ({
      name: 'MyDevices',
      query,
    }),
    alwaysActive: true,
  },
  {
    name: 'Payment',
    items: [
      {
        name: 'Update Recurring Payment',
        path: 'payment',
      },
      {
        name: 'One-time Payment',
        path: 'payment/one-time-payment',
      },
      {
        name: 'Payment History',
        path: 'payment/payment-history',
      },
      {
        name: 'Scheduled Payments',
        path: 'payment/scheduled-payments',
      },
      {
        name: 'Payment Methods',
        path: 'payment/payment-methods',
      },
      {
        name: 'Plan Payment Options',
        path: 'payment/change-payment',
      },
    ],
  },
  {
    name: 'Service',
    path: 'service',
  },
  // TODO: don't show before user name loaded
  {
    name: USER_NAME,
    iconClass: 'user-icon',
    items: [
      {
        name: 'Communication Preferences',
        path: 'communication-preferences',
      },
      {
        name: 'Account Settings',
        path: 'account-settings',
      },
      {
        // TODO: should be blue
        detached: true,
        blue: true,
        name: 'Sign Out',
        path: 'api/Login/Logout',
      },
    ],
  },
];

function setItemsPaths(item) {
  item.href = `${scBase}/sc/${item.path}`;
  item.items?.forEach(setItemsPaths);
}

menu.forEach(setItemsPaths);

export default menu;
