<template>
  <div class="hs-popup" role="dialog" aria-modal="true">
    <div class="hs-popup__container">
      <button v-if="showHide" class="hs-popup__close" @click.prevent="hide">x</button>
      <slot></slot>

      <div v-if="confirmButtonText" class="hs-popup__footer" :class="footerClasses">
        <hs-button
          v-if="declineButtonText"
          :disabled="declineDisabled"
          class="hs-popup__button"
          variant="secondary"
          size="md"
          @click="
            $emit('decline');
            $emit('hide');
          "
          >{{ declineButtonText }}</hs-button
        >
        <hs-button
          :disabled="disabled"
          class="hs-popup__button"
          size="md"
          @click="$emit('confirm')"
          >{{ confirmButtonText }}</hs-button
        >
      </div>
    </div>
    <span class="hs-popup__overlay" @click="hide" @keydown.esc="hide"></span>
  </div>
</template>

<script>
export default {
  props: {
    showHide: {
      type: Boolean,
      default: true,
    },
    declineButtonText: {
      type: String,
    },
    confirmButtonText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    declineDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    closePopup(e) {
      const keyCode = e.charCode || e.keyCode;
      if (keyCode === 27) {
        this.hide();
      }
    },
  },
  computed: {
    footerClasses() {
      const classes = [];
      const isOnlyConfirmButton = !this.declineButtonText && this.confirmButtonText;

      if (isOnlyConfirmButton) {
        classes.push('hs-popup__footer--singular');
      }

      return classes;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.closePopup);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.closePopup);
  },
};
</script>


<style lang="scss">
.hs-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($darkGray, 0.2);
  }
  &__container {
    position: relative;
    z-index: 2;
    flex: 1 0 90vw;
    max-width: 90vw;
    padding: 70px 20px 30px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    @include mf($gridMDBreakpoint) {
      flex: 1 0 635px;
      max-width: 635px;
      padding: 40px;
    }
  }
  &__close {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 44px;
    height: 44px;
    overflow: hidden;
    text-indent: -9999px;
    background-image: url(./close.svg);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 12px 12px;
    border: none;

    @include mf($gridMDBreakpoint) {
      top: 40px;
      right: 40px;
      width: 14px;
      height: 14px;
      background: url(./close.svg) no-repeat 50%/14px 14px;
    }
  }
  &__title {
    margin: 0 20px 0 0;
    color: $black;
    font-size: 20px;
    line-height: 23px;
  }
  &__description {
    margin-top: 30px;
    color: $darkGray;
    font-size: 18px;
    line-height: 130%;
    p {
      margin: 0;
    }
  }
  &__footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;

    &--singular {
      justify-content: center;

      @include mf($gridXSBreakpoint) {
        justify-content: flex-end;
      }
    }

    @include mf($gridXSBreakpoint) {
      flex-direction: row;
    }
  }

  &__button {
    width: 100%;
    min-width: 122px;

    @include mf($gridXSBreakpoint) {
      width: 280px;
    }

    &:is(:last-child) {
      margin-bottom: 16px;
    }

    @include mf($gridXSBreakpoint) {
      &:is(:last-child) {
        margin-bottom: 0;
      }
    }

    @include mf($gridMDBreakpoint) {
      width: unset;
    }
  }
}
</style>
