import {API} from '@/constants/api';
import {getRequestBody} from '@/utils/request';

const state = {
  devices: [],
};

const getters = {
  // ...mapState('jobInfo', ['claimsList']),
  devicesWithClaims: (state, getters, rootState) => {
    let result = [];

    const devices = getters.activeDevices;
    const {claimsList} = rootState.jobInfo;

    if (devices?.length && claimsList?.length) {
      result = devices.map((device) => {
        let claims = [];

        claims = claimsList.filter(
          (claim) => claim?.claimDetails?.inventoryEquipmentID === device.inventoryEquipmentId
        );

        return {
          ...device,
          claims,
        };
      });
    } else {
      result = devices;
    }

    return result;
  },
  activeDevices: (state) => {
    return state.devices.filter(
      (i) => i.equipmentStatus !== 'Inactive' && i.equipmentStatus !== 'Locked'
    );
  },
};

const actions = {
  ['CHECK_90_DAYS_CLAIMS_FOR_DEVICE']: async (store, params) => {
    const payload = getRequestBody({
      requestName: 'getEquipmentEligibilityRequest',
      requestParams: {...params},
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.CHECK_90_DAYS,
          method: 'post',
          payload,
        })
        .then((res) => {
          const result = res?.data?.response?.getEquipmentEligibilityResponse;
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  ['GET_MY_DEVICES']: (store, {customerRef}) => {
    const payload = getRequestBody({
      requestName: 'getEquipmentDetailsRequest',
      requestParams: {
        customerRef,
      },
    });
    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.GET_INFO,
          method: 'post',
          payload,
        })
        .then((res) => {
          let result = res.data;
          if (result.response.getEquipmentDetailsResponse?.errorDetails?.errorDetail) {
            resolve(
              result.response.getEquipmentDetailsResponse?.errorDetails?.errorDetail
                ?.errorDescription
            );
          } else {
            store.commit(
              'SET_MY_DEVICES',
              result?.response?.getEquipmentDetailsResponse?.equipmentDetails?.equipmentDetail
            );
          }
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  ['SET_MY_DEVICES']: (state, devices) => {
    state.devices = devices || [];
  },
  ['REMOVE_DEVICE']: (state, id) => {
    state.devices = state.devices.filter((i) => i.inventoryEquipmentId !== id);
  },
  ['CLEAR_STORE']: (state) => {
    state.devices = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
