<script>
import {ModelSelect} from 'vue-search-select';

export default {
  extends: ModelSelect,
  props: {
    emptyItem: Object,
  },
  computed: {
    filteredOptions() {
      if (this.searchText) {
        let res = this.options.filter((option) => {
          try {
            return this.filterPredicate(option.text, this.searchText);
          } catch (e) {
            return true;
          }
        });
        if (!res?.length && this.emptyItem) res = [this.emptyItem];
        return res;
      } else {
        return this.options;
      }
    },
  },
};
</script>
