// hours: hour24
export function formattedHours(hours, minutes = 0) {
  const dateInstance = new Date();
  dateInstance.setHours(hours, minutes, 0, 0);

  return dateInstance.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

// time: {
//   from: 24hoursFormatHour | [24hoursFormatHour, minutes],
//   to: 24hoursFormatHour | [24hoursFormatHour, minutes]
// }
export function formattedTimeRange(time = {}) {
  const timeFromParams = Array.isArray(time.from) ? time.from : [time.from];
  const timeToParams = Array.isArray(time.to) ? time.to : [time.to];

  const from = formattedHours(...timeFromParams);
  const to = formattedHours(...timeToParams);

  return {
    from,
    to,
  };
}

export function formattedMonthAndDay(dateConstructorParam) {
  return new Date(dateConstructorParam).toLocaleString('en-US', {month: 'long', day: 'numeric'});
}

export function formattedDate(date) {
  const dateArr = date?.split('-');
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
}

export function dateToTimeRange(dateConstructorParam, timeRangeDuration = 4) {
  const date = new Date(dateConstructorParam);
  const hours = date.getHours();

  const timeRange = {
    from: hours,
    to: hours + timeRangeDuration,
  };

  return timeRange;
}

export function formattedWeekday(dateConstructorParam) {
  const dateInstance = new Date(dateConstructorParam);
  const day = dateInstance.toLocaleString('en-US', {weekday: 'long'});
  return day;
}

// dateConstructorParam: YYYY-MM-DD format
// time: { from: 24hoursFormatHour, hoursTo: 24hoursFormatHour }
export function formattedDateAndTime(dateConstructorParam, time) {
  let result = {};

  const date = formattedDate(dateConstructorParam);
  const day = formattedWeekday(dateConstructorParam);
  Object.assign(result, {date, day});

  if (time) {
    const {from, to} = formattedTimeRange(time);
    Object.assign(result, {from, to});
  }

  return result;
}

export default formattedDateAndTime;
