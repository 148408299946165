import {findPhoneNumbersInText} from 'libphonenumber-js';
import * as dateUtils from '@/utils/dateUtils';
import phones from '@/constants/phones';

const getShippingInformation = ({trackingNumber, shippingCarrier}) => {
  return trackingNumber && shippingCarrier
    ? `
      <b>Your shipping carrier is ${shippingCarrier},
      tracking number is ${trackingNumber}</b>.
    `
    : '';
};

export const getters = {
  shippingInformation: (jobInfo, ...args) => {
    const {shippingToCustomerTrackingID, shippingToCustomerVendor} = jobInfo.claimDetails;
    return getShippingInformation(
      {
        trackingNumber: shippingToCustomerTrackingID,
        shippingCarrier: shippingToCustomerVendor,
      },
      ...args
    );
  },
  deviceInfo: (jobInfo) => {
    const device = jobInfo.claimDetails.replacementDetails?.[0] || {};

    const {modelName = 'unknown', specificationDetails = []} = device;

    const nameChunk = modelName;
    const attributesChunks = specificationDetails.map(
      ({specName, specValue}) => `${specName}: ${specValue}`
    );

    const infoChunks = [nameChunk];
    if (attributesChunks.length) {
      infoChunks.push(attributesChunks.join(', '));
    }

    return infoChunks.join(', ');
  },

  timeFrom: (timeRange = {}) => {
    const {from} = dateUtils.formattedTimeRange(timeRange);
    return from;
  },

  timeFromTo: (timeRange = {}) => {
    const {from, to} = dateUtils.formattedTimeRange(timeRange);
    return `from ${from} to ${to}`;
  },

  timeFromToFromDate: (dateConstructorParam, timeRangeDuration = 4) => {
    const timeRange = dateUtils.dateToTimeRange(dateConstructorParam, timeRangeDuration);
    return getters.timeFromTo(timeRange);
  },

  ...dateUtils,
  phones,
};

// replaces all phones with href="tel:${phone}" links
export const addLinksToPhoneNumbers = (text) => {
  let indexDelta = 0;
  for (const phoneEntry of findPhoneNumbersInText(text, 'US')) {
    const {startsAt, endsAt, number} = phoneEntry;

    const phoneLength = endsAt - startsAt;
    const startIndex = startsAt + indexDelta;
    const endIndex = startsAt + phoneLength + indexDelta;
    const inTextPhone = text.substr(startIndex, phoneLength);
    const link = `<a href="${number.getURI()}">${inTextPhone}</a>`;

    text = `${text.substr(0, startIndex)}${link}${text.substr(endIndex)}`;

    indexDelta += link.length - inTextPhone.length;
  }

  return text;
};

function getTextToHtml(getText) {
  return (payload) => {
    let text = typeof getText === 'string' ? getText : getText(payload, getters);

    text = addLinksToPhoneNumbers(text);

    return (
      text
        // TODO: openable terms & conditions
        .replace(/Terms and Conditions/g, '<a href="#" class="hs-link">Terms and Conditions</a>')
        .replace(/Terms & Conditions/g, '<a href="#" class="hs-link">Terms & Conditions</a>')
    );
  };
}

export default function toHtmlForProps(source, ...props) {
  for (const prop of props) {
    if (source[prop]) {
      source[prop] = getTextToHtml(source[prop]);
    }
  }

  for (const value of Object.values(source)) {
    if (value) {
      if (typeof value === 'object') {
        toHtmlForProps(value, ...props);
      } else if (Array.isArray(value)) {
        for (const subValue of value) {
          toHtmlForProps(subValue, ...props);
        }
      }
    }
  }
}
