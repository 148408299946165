<template>
  <section class="hs-month-calendar">
    <section class="hs-month-calendar__switcher-wrapper">
      <button
        class="hs-month-calendar__arrow-btn hs-month-calendar__arrow-btn--prev"
        type="button"
        @click.stop="showPrevMonth"
      ></button>
      <p class="hs-month-calendar__month-text">{{ monthName }} {{ year }}</p>
      <button
        class="hs-month-calendar__arrow-btn hs-month-calendar__arrow-btn--next"
        type="button"
        @click.stop="showNextMonth"
      ></button>
    </section>
    <section class="hs-month-calendar__table hs-month-calendar__table--day-names">
      <div class="hs-month-calendar__table-row">
        <div v-for="index in 7" :key="index" class="hs-month-calendar__table-cell">
          {{ getDayName(index) }}
        </div>
      </div>
    </section>
    <section class="hs-month-calendar__table">
      <div v-for="(week, index) in datesArray" :key="index" class="hs-month-calendar__table-row">
        <div v-for="(day, index) in week" :key="index" class="hs-month-calendar__table-cell">
          <button
            v-if="type == 'full'"
            :class="{
              'hs-month-calendar__day-btn--today': day.today,
              'hs-month-calendar__day-btn--active': shouldDteBeActive(day),
            }"
            :disabled="shouldDateBeDisabled(day, 'full')"
            class="hs-month-calendar__day-btn"
            type="button"
            @click.stop="getData(day.formatedDate)"
          >
            {{ day.date }}
          </button>
          <button
            v-else-if="type == 'past'"
            :class="{
              'hs-month-calendar__day-btn--today': day.today,
              'hs-month-calendar__day-btn--active': shouldDteBeActive(day),
              'hs-month-calendar__day-btn--past': !day.pastDay && !day.today,
            }"
            :disabled="shouldDateBeDisabled(day, 'past')"
            class="hs-month-calendar__day-btn"
            type="button"
            @click.stop="getData(day.formatedDate, !day.pastDay && !day.today)"
          >
            {{ day.date }}
          </button>
          <button
            v-else
            :class="{
              'hs-month-calendar__day-btn--today': day.today,
              'hs-month-calendar__day-btn--active': shouldDteBeActive(day),
              'hs-month-calendar__day-btn--past': day.pastDay,
              'hs-month-calendar__day-btn--not-available': dateIsNotAvailable(
                day,
                shouldDateBeDisabled(day),
                day.pastDay
              ),
            }"
            :disabled="shouldDateBeDisabled(day)"
            class="hs-month-calendar__day-btn"
            type="button"
            @click.stop="
              getData(
                day.formatedDate,
                day.pastDay || dateIsNotAvailable(day, shouldDateBeDisabled(day), day.pastDay)
              )
            "
          >
            {{ day.date }}
          </button>
        </div>
      </div>
    </section>
    <slot></slot>
  </section>
</template>

<script>
import calendarModule from './CalendarConstructor';

export default {
  props: {
    type: {
      type: String,
    },
    activeDate: {
      type: String, // date-format YYYY-MM-DD
      validator: (value) => !value || value.indexOf('-') != -1,
    },
    startCalendarFrom: {type: String},
    availableDates: {type: Array},
  },
  data() {
    return {
      year: '',
      month: '',
      datesArray: [],
      activeDateInt: '',
      calendarConstructor: calendarModule(),
    };
  },
  computed: {
    monthName() {
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ][this.month];
    },
  },
  watch: {
    activeDate() {
      this.setCurrentDate();
    },
  },
  methods: {
    dateIsNotAvailable(day, disabled, past) {
      if (disabled || past || !this.availableDates || !this.availableDates.length) return false;
      return !this.availableDates.includes(day.formatedDate);
    },
    shouldDteBeActive(day) {
      return this.isDateActive(day.formatedDate) && !(day.prevMonth || day.nextMonth);
    },
    shouldDateBeDisabled(day, type) {
      switch (type) {
        case 'full':
          return this.isDateBlocked(day.formatedDate) || day.prevMonth || day.nextMonth;
        case 'past':
          return (
            this.isDateBlocked(day.formatedDate) ||
            day.prevMonth ||
            day.nextMonth ||
            (!day.pastDay && !day.today)
          );
        default:
          return (
            this.isDateBlocked(day.formatedDate) || day.prevMonth || day.nextMonth || day.pastDay
          );
      }
      return false;
    },
    isDateActive(date) {
      return date === this.activeDateInt;
    },
    isDateBlocked(date) {
      if (this.startCalendarFrom) {
        const startDateArr = this.startCalendarFrom.split('-');
        const startDateInst = new Date(startDateArr[0], startDateArr[1], startDateArr[2], 0, 0, 0);
        const callDateArr = date.split('-');
        const callDateInst = new Date(callDateArr[0], callDateArr[1], callDateArr[2], 0, 0, 0);
        return callDateInst < startDateInst;
      }
      return false;
    },
    getDayName(index) {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][
        index - 1
      ]
        .slice(0, 2)
        .toUpperCase();
    },
    getData(date, pastDayIndicator) {
      if (!pastDayIndicator) {
        this.$emit('changeday', date);
        this.setActiveDate(date);
      }
    },
    setCurrentDate() {
      if (this.activeDate) {
        const dateArr = this.activeDate.split('-');
        this.year = +dateArr[0];
        this.month = dateArr[1] - 1;
      } else {
        let now = new Date();
        this.year = now.getFullYear();
        this.month = now.getMonth();
        now = null;
      }
      this.$emit('innerdatechanged', {month: this.month, year: this.year});
      this.setActiveDate();
      this.resetRenderValues();
    },
    showNextMonth(eve) {
      if (eve && eve.target) {
        eve.target.blur();
      }
      if (this.month === 11) {
        this.month = 0;
        this.year += 1;
        this.resetRenderValues();
      } else {
        this.month += 1;
        this.resetRenderValues();
      }
      this.$emit('innerdatechanged', {month: this.month, year: this.year});
    },
    showPrevMonth(eve) {
      if (eve && eve.target) {
        eve.target.blur();
      }
      if (this.month === 0) {
        this.month = 11;
        this.year -= 1;
        this.resetRenderValues();
      } else {
        this.month -= 1;
        this.resetRenderValues();
      }
      if (eve) {
        this.$emit('innerdatechanged', {month: this.month, year: this.year});
      }
    },
    createFormatedDate(date) {
      return `${this.year}-${
        String(+this.month + 1).length > 1 ? +this.month + 1 : '0' + (+this.month + 1)
      }-${String(date).length > 1 ? date : '0' + date}`;
    },
    setActiveDate(param = '') {
      if (this.activeDate !== undefined && this.activeDate !== 'undefined') {
        this.activeDateInt = this.activeDate;
      } else {
        this.activeDateInt = param;
      }
    },
    resetRenderValues() {
      const calendarModuleResult = this.calendarConstructor.setCal(this.month, this.year);
      this.datesArray = calendarModuleResult.calendar;
    },
  },
  mounted() {
    this.setCurrentDate();
    this.resetRenderValues();
  },
};
</script>

<style scoped lang="scss">
.hs-month-calendar {
  padding: 0 20px 20px;
  &__switcher-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
  }
  &__month-text {
    display: inline-block;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }
  &__arrow-btn {
    display: block;
    width: 30px;
    height: 30px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 10px auto;
    border: 0;
    border-radius: 50%;
    &:hover {
      opacity: 0.45;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #99dddd;
    }
    &:active {
      box-shadow: none;
    }
    &--next,
    &--prev {
      background-repeat: no-repeat;
      background-size: 16px auto;
    }
    &--next {
      background-image: url('./icons/arrow-right.svg');
      background-position: 100% 50%;
    }
    &--prev {
      background-image: url('./icons/arrow-left.svg');
      background-position: 0 50%;
    }
  }
  &__table {
    display: table;
    width: 100%;
    &--day-names {
      color: #7f8fa4;
    }
  }
  &__table-row {
    display: table-row;
    font-size: 14px;
  }
  &__table--day-names &__table-row {
    font-size: 12px;
  }
  &__table-cell {
    display: table-cell;
    width: 14.285714286%;
    padding: 4px 0;
    font-weight: 500;
    vertical-align: middle;
  }
  &__day-btn,
  &__table--day-names &__table-cell {
    padding-left: 5px;
  }
  &__day-btn {
    width: calc(100% - 1px);
    height: 26px;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    background-color: transparent;
    border: 0;
    &:hover {
      background-color: $lighterGray;
    }
    &[disabled],
    &--past {
      color: #bac2c7;
      background-color: #edf2f5;
      pointer-events: none;
    }
    &[disabled] {
      cursor: not-allowed;
      &:hover,
      &:active {
        background-color: #edf2f5;
      }
    }
    &--active,
    &--active:hover {
      color: $white;
      font-weight: bold;
      background-color: $darkBlue;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(171, 171, 171, 0.5);
    }
    &--today[disabled] {
      color: #bac2c7;
      cursor: not-allowed;
    }
    &--active:focus {
      outline: none;
      box-shadow: 0 0 0 2px #99dddd;
    }
    &--blocked {
      color: #d83347;
    }
    &--active[disabled] {
      color: $darkGray;
    }
    &--not-available {
      position: relative;
      overflow: hidden;
      background-color: #f6f7f8;
      pointer-events: none;
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: -3px;
        z-index: 1;
        display: block;
        height: 1px;
        background-color: #f2a5a2;
        transform: rotate(-24deg);
        transform-origin: top right;
        content: '';
      }
    }
  }
}
</style>
