<template>
  <nav
    class="navigation"
    :class="{
      'navigation--is-visible': visible,
    }"
    role="navigation"
  >
    <div class="navigation__container" v-on-clickaway="emitHide">
      <div class="navigation__logo-container">
        <Logo class="navigation__logo" />
        <button class="navigation__close-button" @click="emitHide"></button>
      </div>

      <NavigationMenuList always-show-detached-items />
    </div>
  </nav>
</template>
<script>
import NavigationMenuList from '@/components/header/NavigationMenuList';
import Logo from '@/components/shared/Logo';
import {mixin as clickaway} from 'vue-clickaway';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [clickaway],
  components: {
    NavigationMenuList,
    Logo,
  },
  methods: {
    emitHide(e) {
      if (
        this.visible &&
        Array.from(e.target.classList).some((className) => className.includes('navigation'))
      ) {
        this.$emit('hide');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: -300%; // hide nav shadow on mobile.
  z-index: -1;
  display: flex;
  background-color: transparent;
  transition: all 0.35s ease;
  will-change: background-color;

  &--is-visible {
    left: 0;
    z-index: 10;
    max-width: unset;
    background-color: rgba(117, 117, 117, 0.6);
    backdrop-filter: blur(4px);
  }

  &__container {
    width: 75%;
    min-width: 300px;
    overflow: auto;
    background-color: $white;
  }

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__close-button {
    width: 53px;
    height: 53px;
    color: transparent;
    font-size: 1px;
    background-image: url('~@/assets/images/close.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 13px 13px;
  }

  &__logo {
    width: 137px;
    margin: 49px 42px;
  }

  &::v-deep {
    .navigation-menu-list {
      display: block;
      margin-right: 0;
      border-top: 1px solid $gray;

      &__list {
        transform: scale(1);

        &--child {
          position: static;
          align-items: unset;
          width: 100%;
          height: 0;
          padding: 0;
          overflow: hidden;
          border-top: none;
          box-shadow: none;
        }

        &--hidden {
          padding: 0;
          transform: scale(1);
        }
      }

      &__list-item {
        display: block;

        &--nested {
          margin-left: 42px;
          padding: 18px 0;
          font-size: 16px;
          border-bottom: 1px solid $gray;
        }

        &--detached-nested {
          border-bottom: 1px solid $gray;
        }

        &--arrow {
          &::before {
            top: 25px;
            right: 20px;
            width: 15px;
            height: 10px;
            background: url(~@/assets/images/nav-back-larger.svg) no-repeat 0 0/15px 10px;
          }
        }

        &--detached {
          border-bottom: none;
        }
      }

      &__link {
        width: 100%;
        margin: 0 0 1px 0;
        padding: 18px 42px 18px 37px;
        font-size: 16px;
        text-align: left;
        border-bottom: 1px solid $gray;
        border-left: 9px solid transparent;
        transition: border-color 0.2s ease 0s;

        &.router-link-exact-active {
          color: $primaryRed;
          font-weight: normal;
          border-left: 9px solid $primaryRed;
        }

        &--active {
          font-weight: bold;
          border-left: 9px solid $primaryRed;
        }
      }

      &__icon {
        display: inline-block;
      }
    }
  }
}
</style>
