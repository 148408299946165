<template>
  <hs-popup
    v-if="contracts.length > 1"
    :show-hide="false"
    aria-labelledby="select-contract-title"
    aria-describedby="select-contract-options"
    :disabled="!isContractSelected"
    confirm-button-text="Continue"
    @confirm="select"
  >
    <h1 class="hs-popup__title" id="select-contract-title">
      Please select under which of the following plan you would like to place this service request
    </h1>
    <div class="hs-popup__description select-contract__options" id="select-contract-options">
      <select-contract-option
        :key="contract.contractPublicRef"
        :selected="selectedContract === contract.contractPublicRef"
        :showFullDescription="showFullDescription"
        :contract="contract"
        @setContract="setContract"
        v-for="contract in contracts"
      />
    </div>
  </hs-popup>
</template>

<script>
import {mapState} from 'vuex';
import SelectContractOption from '@/components/shared/SelectContractOption';

export default {
  components: {
    SelectContractOption,
  },
  data() {
    return {
      selectedContract: null,
      contractLoading: false,
    };
  },
  computed: {
    ...mapState('user', ['customerDetails', 'contracts']),
    isContractSelected() {
      return !!this.selectedContract && !this.contractLoading;
    },
    showFullDescription() {
      const contractTypes = this.contracts.map((v) => v.contractType);
      const uniqueTypes = new Set(contractTypes);
      return contractTypes.length !== uniqueTypes.size;
    },
  },
  watch: {
    contracts: {
      handler(loadedContracts) {
        //console.log('contracts');
        //console.log(this.contracts);
        if (loadedContracts.length === 1) {
          this.selectedContract = loadedContracts[0]?.contractPublicRef;
          this.select(true);
        }
      },
      immediate: true,
    },
  },
  methods: {
    setContract(id) {
      this.selectedContract = id;
    },
    async select(preventReload) {
      this.contractLoading = true;
      const encrypted = await this.$store.dispatch('user/ENCRYPT_PARAMS', {
        contractRef: this.selectedContract,
      });
      let {contractRef} = encrypted;
      contractRef = decodeURIComponent(contractRef);
      const query = {
        ...this.$route.query,
        contractRef,
      };
      const queryModified = Object.entries(query).some(
        ([key, value]) => this.$route.query[key] !== value
      );
      if (queryModified) {
        this.$router.replace({
          query,
        });
      }
      this.contractLoading = false;
      this.$store.dispatch('user/HIDE_SELECT_CONTRACT_MODAL');
      if (!preventReload) location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.select-contract {
  &__options {
    border-bottom: 1px solid #eceae6;
  }
}
</style>
