import toHtmlForProps from '@/utils/toHtmlForProps';
import {getSharedStatuses, getStatusCodesResolver} from '@/utils/jobStatuses';

// TODO: make sure that in transit statuses are applicable
//       to replacement flow and not just depot service

const sharedStatuses = getSharedStatuses({
  serviceCompleted: {
    text: 'Your Replacement device request has successfully been completed.',
  },
});

const buyoutReadyForDecisionText = (claim) => {
  let amount = 'a buyout amount';
  let buyoutAmount = claim?.claimDetails?.buyoutAmount;
  if (buyoutAmount) {
    amount = `$${parseInt(buyoutAmount, 10)}`;
  }
  return `
    We will pay you ${amount} based on the fair market value of your device at the time the Service Call was made, up to the benefit limit amount minus the Service Call fee. Once you accept this buyout option, payment processing and delivery will take up to 30 days.
    <b>Note: Your device will be automatically removed from your device list. 
    Go to Service History to check on your Service Call progress.</b>
  `;
};

export const replacementStatuses = {
  serviceCancelled: {
    code: ['SC0003', 'CAN', 'Replacement claim cancelled by customer'],
    virtualStatus: 'Service cancelled',
    heading: 'Service cancelled',
    text: (_, {phones}) => `
      You have cancelled this Service request.
      If you have any questions, please call HomeServe at ${phones.contactCenter}.
    `,
  },
  replacementAuthorized: {
    code: ['NEW', 'SC0101', 'Replacement authorized'],
    virtualStatus: 'Replacement authorized', // workaround for not working update of serviceJobSubStatusName
    text: 'You will soon be offered a replacement option. We will notify you via email/SMS when available replacement option will be ready to review.',
  },

  replacementOptionProvided: {
    code: 'SC0102',
    heading: 'Replacement option provided, please review it',
    text: 'You have 24 hours to review the replacement option and to accept it. If you choose not to proceed with Replacement, Buyout option is also available.',
  },

  replacementOptionAccepted: {
    code: 'SC0106',
    heading: 'You’ve accepted the replacement option',
    virtualStatus: 'Replacement option accepted',
    text: 'You will soon receive replacement confirmation and shipping details for device delivery to your confirmed address.',
  },

  replacementUnavailable: {
    code: ['SC0100', 'SC0104'],
    heading: 'Replacement unavailable. A Buyout option is available.',
    text: (claim) => {
      const buyoutAmountText = claim.claimDetails?.buyoutAmount;
      const amount = buyoutAmountText ? Number(buyoutAmountText).toFixed(0) : 'a buyout amount';

      return `
        We will pay you $${amount} based on the fair market value of your device at the time the Service Call was made up to the benefit limit amount. Once you accept this buyout option, payment processing and delivery will take up to 30 days. 
        <b>Note: Your device will be automatically removed from your device list. 
        Go to Service History to check on your Service Call progress.</b>
      `;
    },
  },

  replacementAllocated: {
    code: 'SC0105',
    // TODO: adjust when data will be available
    text: (jobInfo, {deviceInfo}) => `
      New replacement device confirmed: ${deviceInfo(jobInfo)}.
      Shipping details for device delivery to your home address will be sent shortly.
    `,
  },

  deviceDispatched: {
    code: 'SC0081',
    virtualStatus: 'Device dispatched',
    heading: 'Device dispatched',
    text: (jobInfo) => {
      const {shippingToCustomerTrackingID, shippingToCustomerVendor} = jobInfo.claimDetails;
      return `
        Your device is on its way via <b>shipping carrier ${shippingToCustomerVendor},
        tracking number ${shippingToCustomerTrackingID}</b>.
      `;
    },
  },

  deviceWithLogisticsPartner: {
    code: 'SC0068',
    virtualStatus: 'Service Call in progress',
    heading: 'Your Service Call is in progress',
    text: 'We are in the process of resolving your request and your Service Call is in progress.',
  },

  // Delivery statuses
  deviceInTransit: {
    code: 'SC0069',
    heading: 'Device is in-transit',
    // TODO: adjust when data will be available
    text: (jobInfo, {deviceInfo, shippingInformation}) => `
      New replacement device confirmed: ${deviceInfo(jobInfo)}.
      Your device is on its way.
    `,
  },

  deviceOutForDelivery: {
    code: 'SC0071',
    virtualStatus: 'Out for delivery',
    heading: 'Out for delivery',
    text: (jobInfo, {deviceInfo, shippingInformation}) => `
      New replacement device confirmed: ${deviceInfo(jobInfo)}.
      Your device is on its way.
    `,
  },

  deliveryAttemptFailed: {
    code: 'SC0072',
    heading: 'Device delivery attempt failed',
    // TODO: adjust when data will be available
    text: (jobInfo, {deviceInfo, shippingInformation, phones}) => `
      New replacement device confirmed: ${deviceInfo(jobInfo)}.
      We tried to deliver your device, but the delivery was unsuccessful.
      We will try again.
      Please reach out to the shipping carrier for details or call HomeServe at ${
        phones.contactCenter
      }.
    `,
  },

  returnToShipper: {
    code: 'SC0075',
    // TODO: adjust when data will be available
    text: (jobInfo, {deviceInfo, shippingInformation, phones}) => `
      New replacement device confirmed: ${deviceInfo(jobInfo)}.
      We tried 3 times to deliver your device, but all attempts were unsuccessful. Your device is on its way back. If you have any questions, please call HomeServe at ${
        phones.contactCenter
      }.
    `,
  },

  deviceDelivered: {
    code: 'SC0070',
    statusApiText: 'Device delivered',
    text: `
      Your new replacement device has been successfully delivered to your confirmed address.
      <b>
        Note: the failed device (that has now been replaced) is no longer eligible for any coverage.
        It has been automatically removed from your device list.
        We recommend that you register your new device for coverage with your current plan by adding it to your device list.
      </b>.
    `,
  },
};

const replacementRefusalStatuses = {
  replacementDeclined: {
    code: 'HS003',
    virtualStatus: 'Ready for Decision',
    heading: 'Please review offered buyout',
    text: buyoutReadyForDecisionText,
  },
  replacementExpired: {
    code: 'HS004',
    virtualStatus: 'Replacement Expired. Buyout Ready For Decision.',
    heading: 'Replacement Expired. Buyout Ready For Decision.',
    text: buyoutReadyForDecisionText,
  },
};

export const statuses = {
  ...sharedStatuses,
  ...replacementRefusalStatuses,
  ...replacementStatuses,
};

toHtmlForProps(statuses, 'text');

export const statusCodesResolver = getStatusCodesResolver(statuses);

export default statuses;
