import {API} from '@/constants/api';
import {getRequestBody} from '@/utils/request';

const state = {
  productLine: '',
  productLineName: '',
  make: '',
  model: '',
  modelName: '',
  equipmentName: '',
  serialNumber: '',
  createdDate: '',
  equipmentStatus: '',
  inventoryEquipmentId: '',
  claims: [],
};

const getters = {
  make: ({make}) => make,
  model: ({model}) => model,
  serialNumber: ({serialNumber}) => serialNumber,
};

const actions = {
  ['GET_DEVICE_INFO']: async (store, {id, customerRef}) => {
    let res;

    const payload = getRequestBody({
      requestName: 'getEquipmentDetailsRequest',
      requestParams: {
        customerRef,
        inventoryEquipmentId: id,
      },
    });

    try {
      res = await store.rootState.actionsDecorator({
        store,
        path: API.DEVICE.GET_INFO,
        method: 'post',
        payload,
      });
    } catch (error) {
      console.error(error);
    }
    if (res) {
      store.commit(
        'SET_DEVICE_INFO',
        res?.data?.response?.getEquipmentDetailsResponse?.equipmentDetails?.equipmentDetail[0]
      );
    }
  },
  ['SAVE_DEVICE_INFO']: async (store, {id, nickname, sn}) => {
    const payload = getRequestBody({
      requestName: 'updateEquipmentDetailsRequest',
      requestParams: {
        inventoryEquipmentId: id,
        equipmentName: nickname,
        serialNumber: sn,
      },
    });

    return new Promise((resolve, reject) => {
      store.rootState
        .actionsDecorator({
          store,
          path: API.DEVICE.UPDATE_INFO,
          method: 'post',
          payload,
        })
        .then((res) => {
          store.commit('SET_DEVICE_INFO', res?.data?.response?.updateEquipmentDetailsResponse);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  ['SET_DEVICE_INFO']: (
    state,
    {
      productLine,
      productLineName,
      make,
      model,
      modelName,
      equipmentName,
      serialNumber,
      createdDate,
      claims,
      equipmentStatus,
      inventoryEquipmentId,
    }
  ) => {
    state.productLine = productLine || state.productLine;
    state.productLineName = productLineName || state.productLineName;
    state.make = make || state.make;
    state.model = model || state.model;
    state.modelName = modelName || state.modelName;
    state.equipmentName = equipmentName;
    state.serialNumber = serialNumber || state.serialNumber;
    state.equipmentStatus = equipmentStatus || state.equipmentStatus;
    state.inventoryEquipmentId = inventoryEquipmentId || state.inventoryEquipmentId;
    if (createdDate) {
      state.createdDate = createdDate || state.createdDate;
    }
    if (claims) {
      state.claims = claims || state.claims;
    }
  },
  ['UPDATE_NICKNAME']: (state, equipmentName) => {
    state.equipmentName = equipmentName;
  },
  ['UPDATE_SERIAL_NUMBER']: (state, serialNumber) => {
    state.serialNumber = serialNumber;
  },
  ['CLEAR_STORE']: (state) => {
    state.productLine = '';
    state.productLineName = '';
    state.make = '';
    state.model = '';
    state.modelName = '';
    state.equipmentName = '';
    state.serialNumber = '';
    state.equipmentStatus = '';
    state.inventoryEquipmentId = '';
    state.createdDate = '';
    state.claims = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
