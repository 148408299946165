import formattedDateAndTime from '@/utils/dateUtils';
import {API} from '@/constants/api';
import {getRequestBody} from '@/utils/request';
import generateGuardedAsyncActions from '@/store/generateGuardedAsyncActions';

const guardedActions = generateGuardedAsyncActions({
  async GET_SLOTS(context) {
    const {rootState, rootGetters, commit} = context;
    const {actionsDecorator} = rootState;

    const zipCode = rootGetters['requestService/zipcode'];

    const {productCode, brandCode, productLine} = rootState.requestService;

    const serviceTypeCode = 'CLAIM_ONSITE';
    const serviceLocationCode =
      rootGetters['requestService/getServiceLocationCode'](serviceTypeCode);

    const payload = getRequestBody({
      requestName: 'getSlotlistRequest',
      requestParams: {
        serviceTypeCode,
        zipCode,

        productCode,
        brandCode,
        productSubCategoryCode: productLine,
        serviceLocationCode,
        countryCode: 'US',
      },
    });

    const response = await actionsDecorator({
      context,
      path: API.CLAIMS.GET_SLOTS,
      method: 'post',
      payload,
    });

    commit('SET_SLOTS', response.data?.response?.getSlotlistResponse?.availableSlots);
  },
});

const getEmptyState = () => ({
  phoneNumber: null,
  slots: null,
  date: null,
  chosenTimeSlotCode: null,
});

const state = {
  ...guardedActions.state,
  ...getEmptyState(),
};

const mutations = {
  ...guardedActions.mutations,
  SET_PHONE_NUMBER: (state, phoneNumber = null) => Object.assign(state, {phoneNumber}),
  CLEAR_DATETIME: (state) =>
    Object.assign(state, {
      date: null,
      slots: null,
      chosenTimeSlotCode: null,
    }),
  SET_CHOSEN_DATE: (state, date = null) => Object.assign(state, {date}),
  SET_CHOSEN_TIME_SLOT_CODE: (state, chosenTimeSlotCode = null) =>
    Object.assign(state, {chosenTimeSlotCode}),
  SET_SLOTS: (state, slots = null) => Object.assign(state, {slots}),
  CLEAR_STORE: (state) => Object.assign(state, getEmptyState()),
};

const getters = {
  formatted: (state) => {
    const {chosenDate, chosenTime} = state;
    return formattedDateAndTime(chosenDate, chosenTime);
  },

  selectedSlot: ({slots, date}) => slots.find((slot) => slot.date === date),
  selectedTimeSlot: ({chosenTimeSlotCode}, {selectedSlot}) =>
    selectedSlot.slots.find((slot) => slot.slotCode === chosenTimeSlotCode),

  initClaimPayloadParams: ({date, phoneNumber}, {selectedTimeSlot = {}}) => {
    const {startTimeval, endTimeval} = selectedTimeSlot;

    return {
      requestParams: {
        mobileNumber: phoneNumber,
      },
      consumerServiceRequestParams: {
        scheduledDateTime: date,
        scheduledFromTime: startTimeval,
        scheduledToTime: endTimeval,
      },
    };
  },
};

const actions = {
  ...guardedActions.actions,
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};
