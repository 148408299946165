<template>
  <div>
    <p-checkbox :checked="checked" class="hs-switch p-switch p-svg p-fill" @change="handleChange">
      <slot></slot>
    </p-checkbox>
  </div>
</template>

<script>
import PrettyCheck from 'pretty-checkbox-vue/check';

export default {
  components: {
    'p-checkbox': PrettyCheck,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: Boolean,
  },
  methods: {
    handleChange(checked) {
      this.$emit('change', checked);
    },
  },
};
</script>

<style lang="scss">
@import '~pretty-checkbox/src/pretty-checkbox.scss';
$switchDisabledBGColor: #eff0f1;
$switchWidth: 45px;
$switchHeight: 25px;
$switchPadding: 2px;
$switchSwitcherDimensions: 21px;
$switchSwitcherBoxShadow: 1px 0 2px rgba(0, 0, 0, 0.15);

.hs-switch {
  &.pretty.p-switch .state {
    display: flex;
    align-items: center;
  }
  &.pretty.p-switch .state::before {
    position: static;
    display: block;
    width: $switchWidth;
    height: $switchHeight;
    background-color: $lightGray;
    border: 0;
  }
  &.pretty.p-switch .state label {
    text-indent: initial;
  }
  &.pretty .state label::before {
    display: none;
  }
  &.pretty.p-switch .state label::after {
    top: $switchPadding;
    left: $switchPadding;
    width: $switchSwitcherDimensions;
    height: $switchSwitcherDimensions;
    background-color: $white !important;
    box-shadow: $switchSwitcherBoxShadow;
    transform: none;
  }
  &.pretty.p-switch input:checked ~ .state::before {
    background-color: $blue !important;
  }
  &.pretty.p-switch.p-fill input:checked ~ .state label::after {
    left: calc(#{$switchWidth} - #{$switchPadding} - #{$switchSwitcherDimensions});
  }
}
</style>
