<template>
  <hs-main class="wrapper-with-close-btn">
    <button class="wrapper-with-close-btn__btn" @click="handleBack">CLOSE</button>
    <slot></slot>
  </hs-main>
</template>

<script>
import WrapperWithBackBtn from '@/components/shared/WrapperWithBackBtn';

export default {
  extends: WrapperWithBackBtn,
};
</script>

<style scoped lang="scss">
.wrapper-with-close-btn {
  position: relative;
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 12px;
    height: 12px;
    overflow: hidden;
    text-indent: -9999px;
    background-image: url(~@/assets/images/add-device-close.svg);
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 12px 12px;
  }
}
</style>
