<template>
  <a href="#" class="auth__btn--login" @click.prevent="login">
    <template v-if="$slots.default">
      <slot></slot>
    </template>
    <template v-else> Sign In </template>
  </a>
</template>

<script>
export default {
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
  },
};
</script>

<style lang="scss" scoped>
.auth__btn--login {
  display: inline-flex;
  align-items: center;
  color: $blue;
  text-decoration: none;
}
</style>
