<template>
  <hs-main>
    <button class="hs-link page-back-btn" @click="handleBack">BACK</button>
    <slot></slot>
  </hs-main>
</template>

<script>
export default {
  props: {
    link: [String, Object],
    preventNavigation: Boolean,
  },
  methods: {
    handleBack() {
      if (this.preventNavigation) {
        this.$emit('back');
      } else if (this.link) {
        this.$router.push(this.link);
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-back-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  &::before {
    display: block;
    align-self: stretch;
    width: 7px;
    margin-right: 7px;
    background: url(~@/assets/images/nav-back.svg) no-repeat 50% 50% / contain;
    content: '';
  }
}
</style>
